import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { MdChevronLeft } from 'react-icons/md';
import { Input, Button } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminEditUserPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const [isTraveled, setIsTraveled] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const q = query(collection(db, 'Users'), where('uid', '==', id));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
        setUserId(data.customer_id);
        setUserName(data.firstName + ' ' + data.lastName);
        setUserEmail(data.email);
        setUserPhone(data.phone);
        setIsTraveled(data.type === 'driver');
      } catch {
        CustomToast('error', 'No se pudo cargar la información del usuario');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const userRef = query(collection(db, 'Users'), where('uid', '==', id));
      const findUsers = await getDocs(userRef);
      findUsers.forEach(async (user) => {
        const getUser = doc(db, 'Users', user?.id);
        await updateDoc(getUser, {
          type: isTraveled ? 'driver' : 'customer',
        });
      });
    } catch {
      CustomToast('error', 'No se pudo guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Usuario
      </h2>
      <p className="label">ID</p>
      <Input
        type="text"
        className="mainInput"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        placeholder="El ID del usuario"
        disabled
      />
      <p className="label">Nombre</p>
      <Input
        type="text"
        className="mainInput"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="El nombre del usuario"
        disabled
      />
      <p className="label">Correo</p>
      <Input
        type="text"
        className="mainInput"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        placeholder="El correo del usuario"
        disabled
      />
      <p className="label">Teléfono</p>
      <Input
        required
        type="text"
        className="mainInput"
        value={userPhone}
        onChange={(e) => setUserPhone(e.target.value)}
        placeholder="El teléfono del usuario"
      />

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={isTraveled}
          onChange={(e) => setIsTraveled(e.target.checked)}
        />
        <label
          className="form-check-label text-white"
          htmlFor="flexCheckDefault"
        >
          Es Viajero
        </label>
      </div>

      <Button
        onClick={saveChanges}
        className="primaryBtn"
        style={{ marginTop: '0.25rem' }}
      >
        Guardar
      </Button>
    </div>
  );
}

import React from 'react';
import { Modal, Input, Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';

const CreateDeliveryAddressModal = ({
  isOpen,
  toggle,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  country,
  setCountry,
  createNewDeliveryAddress,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <IoClose onClick={toggle} />
        </div>
        <p className="cardHeader">Crear direccion de envío</p>
        <div style={{ marginBottom: '1rem' }}>
          <p className="label">Dirección</p>
          <Input
            type="text"
            className="mainInput"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            placeholder="La nueva dirección de envío"
          />
          <p className="label">Ciudad</p>
          <Input
            type="text"
            className="mainInput"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="La ciudad de la nueva dirección de envío"
          />
          <p className="label">País</p>
          <Input
            type="text"
            className="mainInput"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="El país de la nueva dirección de envío"
          />
        </div>
        <Button
          onClick={createNewDeliveryAddress}
          className="primaryBtn"
          disabled={!streetAddress || !city || !country}
        >
          Crear
        </Button>
      </div>
    </Modal>
  );
};

export default CreateDeliveryAddressModal;

import React from 'react';
import { BarLoader } from 'react-spinners';
import './ModalLoader.css';

export default function ModalLoader() {
  return (
    <div className="loadingModal">
      <BarLoader color="#FFD000" />
    </div>
  );
}

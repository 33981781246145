import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { Button, Input } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export const AdminEditCategoryPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      category_id: '',
      name: '',
      weight: '',
      status: true,
    },
  });
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      try {
        const response = await firebaseService.findOneCategoryWeight(id);
        setValue('category_id', response[0].category_id);
        setValue('name', response[0].name);
        setValue('weight', response[0].weight);
        setValue('status', response[0].status);
      } catch {
        CustomToast(
          'error',
          'No se pudo cargar la información de la categoría',
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await firebaseService.updateCategoryWeight(data.category_id, data);
      navigate(-1);
    } catch {
      CustomToast('error', 'No se pudo actualizar la categoría');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Actualizando Categoria
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">ID</p>
        <Input
          disabled
          type="text"
          className="mainInput"
          placeholder="123456"
          defaultValue={getValues('category_id')}
          onChange={(e) => setValue('category_id', e.target.value)}
        />

        <p className="label">Nombre</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="Computadoras"
          defaultValue={getValues('name')}
          onChange={(e) => setValue('name', e.target.value)}
        />

        <p className="label">Peso</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="0"
          defaultValue={getValues('weight')}
          onChange={(e) => setValue('weight', e.target.value)}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input text-white"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked={getValues('status')}
              onChange={(e) => setValue('status', e.target.checked)}
            />
            <label
              className="form-check-label text-white"
              htmlFor="flexCheckChecked"
            >
              Categoria activa
            </label>
          </div>
        </div>

        <Button
          type="submit"
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
          disabled={isLoading}
        >
          Actualizar
        </Button>
      </form>
    </div>
  );
};

import React from 'react';
import classnames from 'classnames';

const CardOption = ({
  id,
  isActive,
  onClick,
  title,
  description,
  disabled,
}) => {
  return (
    <div
      onClick={() => !disabled && onClick(id)}
      className={classnames(
        'cartOptionItem',
        { active: isActive },
        { disabled },
      )}
      style={disabled ? { cursor: 'not-allowed' } : {}}
    >
      <p style={{ fontWeight: 'bold' }}>{title}</p>
      {description && <p>{description}</p>}
    </div>
  );
};

export default CardOption;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { Button, Input } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export const AdminCreateCategoryPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const firebaseService = FirebaseService.getInstance();

  const navigate = useNavigate();
  const { setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      category_id: '',
      name: '',
      weight: '',
      status: true,
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await validateId(data.category_id);
      await firebaseService.createCategoryWeight(data);
      navigate(-1);
    } catch {
      CustomToast('error', 'Ocurrió un error al crear la categoria');
    } finally {
      setIsLoading(false);
    }
  };

  const validateId = async (id) => {
    const response = await firebaseService.validateCategoryWeight(id);
    if (!response) throw new Error('El ID ya existe');
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Creando Categoria
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">ID</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="123456"
          defaultValue={getValues('category_id')}
          onChange={(e) => setValue('category_id', e.target.value)}
        />

        <p className="label">Nombre</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="Computadoras"
          defaultValue={getValues('name')}
          onChange={(e) => setValue('name', e.target.value)}
        />

        <p className="label">Peso</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="0"
          defaultValue={getValues('weight')}
          onChange={(e) => setValue('weight', e.target.value)}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input text-white"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked={getValues('status')}
              onChange={(e) => setValue('status', e.target.checked)}
            />
            <label
              className="form-check-label text-white"
              htmlFor="flexCheckChecked"
            >
              Categoria activa
            </label>
          </div>
        </div>

        <Button
          type="submit"
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
          disabled={isLoading}
        >
          Crear
        </Button>
      </form>
    </div>
  );
};

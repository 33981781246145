import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAX_PRODUCTS_PER_PAGE } from '../../../common/contants';
import TextLoader from '../../../components/Loaders/TextLoader/TextLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import { AmazonProductCard } from '../../../components/Products/Card/Amazon/AmazonProductCard';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { cancelAllRequests } from '../../../util/axios-req';
import { SheinProductCard } from '../../../components/Products/Card/Shein/SheinProductCard';
import { cleanAmazonUrl, isAmazonUrl, isSheinUrl } from '../../../util/url';
import { sortMixedSources } from '../../../util/array';
import { getGoodIdFromUrl } from '../../../util/shein';
import SheinService from '../../../services/shein.service';
import AmazonService from '../../../services/amazon.service';
import FirebaseService from '../../../services/firebase.service';

export function SearchPage() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [pageLimit, setPageLimit] = useState(MAX_PRODUCTS_PER_PAGE);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [categoryWeights, setCategoryWeights] = useState([]);
  const [productHistory, setProductHistory] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const sheinService = SheinService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const params = new URLSearchParams(location.search);
  const query = params.get('q');
  const source = params.get('s') || 'all';
  const page = parseInt(params.get('p')) || 1;

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !query || !source || !page) return;
      const sourceHandlers = {
        amazon: (query) =>
          isAmazonUrl(query)
            ? search(cleanAmazonUrl(query), amazonService.url, (res) => [
                res.product,
              ])
            : search(query, amazonService.search, (res) => res?.searchResults),
        shein: (query) =>
          isSheinUrl(query)
            ? search(
                getGoodIdFromUrl(query),
                sheinService.search,
                (res) => res?.info?.products,
              )
            : search(query, sheinService.search, (res) => res?.info?.products),
        all: (query) =>
          isAmazonUrl(query)
            ? search(cleanAmazonUrl(query), amazonService.url, (res) => [
                res.product,
              ])
            : isSheinUrl(query)
              ? search(
                  getGoodIdFromUrl(query),
                  sheinService.search,
                  (res) => res?.info?.products,
                )
              : Promise.all([
                  search(
                    query,
                    amazonService.search,
                    (res) => res?.searchResults,
                  ),
                  search(
                    query,
                    sheinService.search,
                    (res) => res?.info?.products,
                  ),
                ]),
      };
      try {
        resetValues();
        setLoading(true);
        const productPromise = sourceHandlers[source](query);
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistoryPromise = searchProductHistory();
        const categoryWeightsPromise = searchCategoryWeights();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistoryPromise,
          categoryWeightsPromise,
        ]);
      } catch {
        CustomToast('error', 'Error al buscar productos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [query, source, page]);

  const sortedResults = useMemo(() => {
    if (!loading && source === 'all' && searchResults.length > 0)
      return sortMixedSources(searchResults);
    return searchResults;
  }, [loading, source, searchResults]);

  const resetValues = async () => {
    cancelAllRequests();
    setSearchResults([]);
    setPageLimit(MAX_PRODUCTS_PER_PAGE);
  };

  const search = async (query, searchFunction, resultPath) => {
    if (!query || !query.trim()) return;
    try {
      const response = await searchFunction(query);
      const results = resultPath(response);
      if (results?.length > 0)
        setSearchResults((prev) => [...prev, ...results]);
    } catch {
      CustomToast('error', 'Error al buscar productos');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchCategoryWeights = async () => {
    try {
      const response = await firebaseService.findActiveCategoryWeights();
      if (response.length > 0) setCategoryWeights(response);
    } catch {
      CustomToast('error', 'Error al buscar pesos de categorías');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const addMoreProducts = () => {
    setPageLimit((prev) => prev + MAX_PRODUCTS_PER_PAGE);
  };

  const handleIncreasePage = (increase) => {
    if (increase) navigate(`/search?q=${query}&s=${source}&p=${page + 1}`);
    else if (page > 1) navigate(`/search?q=${query}&s=${source}&p=${page - 1}`);
  };

  if (loading)
    return (
      <div className="loadingPage">
        <TextLoader
          texts={[
            'Buscando en nuestra base de datos',
            'Definiendo precios todo incluido',
            'Calculando fecha de entrega',
            'Ya casi terminamos',
            'Personalizando tu página de productos',
          ]}
        />
      </div>
    );

  const productCardComponents = {
    amazon: (item, index) => (
      <AmazonProductCard
        key={index}
        product={item}
        listTrips={trips}
        listDrivers={drivers}
        productHistory={productHistory}
        categoryWeights={categoryWeights}
      />
    ),
    shein: (item, index) => <SheinProductCard key={index} product={item} />,
  };

  return (
    <>
      <div id="productGrid">
        {sortedResults
          .slice(0, pageLimit)
          .map((item, index) =>
            productCardComponents[item?.source]
              ? productCardComponents[item?.source](item, index)
              : null,
          )}
      </div>
      {sortedResults?.length === 0 ? (
        <p>No se encontraron resultados.</p>
      ) : (
        <>
          {sortedResults.length > pageLimit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px',
              }}
            >
              <button className="btn btn-warning" onClick={addMoreProducts}>
                Ver más
              </button>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <FaChevronLeft
              onClick={() => handleIncreasePage(false)}
              style={{
                color: '#FFD000',
                cursor: 'pointer',
                width: 25,
                height: 25,
              }}
            />
            <p
              style={{
                marginBottom: 0,
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            >
              {page}
            </p>
            <FaChevronRight
              onClick={() => handleIncreasePage(true)}
              style={{
                color: '#FFD000',
                cursor: 'pointer',
                width: 25,
                height: 25,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}

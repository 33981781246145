import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { calculateAmazonPrice } from '../../../../util/price';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import {
  calculateWeight,
  getAsinFromProductDetails,
  getPriceFromProductDetails,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
  getWeigthItemDimesion,
} from '../../../../util/amazon';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import TextLoader from '../../../Loaders/TextLoader/TextLoader';
import { useNavigate } from 'react-router-dom';
import AmazonService from '../../../../services/amazon.service';
import StarRating from '../../../Reviews/StarRating/StarRating';
import AmazonAddModal from '../../Modals/Amazon/AmazonAddModal';

export const AmazonProductCard = ({
  product,
  listTrips = [],
  listDrivers = [],
  productHistory = [],
  categoryWeights = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [productReportModal, setProductReportModal] = useState(false);
  const [productAddModal, setProductAddModal] = useState(false);

  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!product || productDetails) return;
      try {
        const response = await amazonService.detail(product.asin);
        setProductDetails(response?.product || null);
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [product, productDetails]);

  const variantOptions = useMemo(() => {
    if (!productDetails) return null;
    return productDetails?.variants;
  }, [productDetails]);

  useEffect(() => {
    if (loading || !productDetails) return;
    console.debug(productDetails);
  }, [loading, productDetails]);

  const salePrice = useMemo(() => {
    if (!productDetails) return null;
    const asinProduct = getAsinFromProductDetails(productDetails);
    const categoryList =
      productDetails?.categories?.map((value) =>
        value.category_id?.toLowerCase(),
      ) || [];
    let price = getPriceFromProductDetails(productDetails);
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth || '';
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && Number(price) > 500) ||
      (historyPrice && Number(historyPrice) > 500)
    )
      return null;
    if (historyPrice) price = Number(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(productDetails);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = productDetails?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(productDetails),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (weightDefault) {
      if (Number(weightDefault) > 15) return null;
      if (Number(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightDefault) || 0,
        shippingAmazon || 0,
      );
    }
    return null;
  }, [productDetails]);

  const originalPrice = useMemo(() => {
    if (!productDetails) return null;
    const price = productDetails?.buybox_winner?.rrp?.value;
    if (!price || Number(price) > 500) return null;
    const asinProduct = getAsinFromProductDetails(productDetails);
    const categoryList =
      productDetails?.categories?.map((value) =>
        value.category_id?.toLowerCase(),
      ) || [];
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth || '';
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(productDetails);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = productDetails?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(productDetails),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (weightDefault) {
      if (Number(weightDefault) > 15) return null;
      if (Number(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        Number(weightDefault) || 0,
        shippingAmazon || 0,
      );
    }
    return null;
  }, [productDetails]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon =
      productDetails?.buybox_winner?.fulfillment?.standard_delivery?.date || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = listTrips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = listDrivers.find(
        (user) => user?.uid === info.travelerUid,
      );
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = listDrivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + Number(daysAfterTravel));
    return dateTravel;
  }, [productDetails, listTrips, listDrivers]);

  const handleAddToCartModal = (e) => {
    e.stopPropagation();
    setProductAddModal(true);
  };

  return (
    <div
      className="product"
      onClick={() => navigate(`/detail/${product.asin}`)}
    >
      <div className="productImageContainer">
        <img
          alt="productImage"
          src={product.image || product.main_image.link}
          className="productImage"
        />
      </div>
      <div className="productContent">
        <p className="productName">{product.title}</p>

        {product?.rating && (
          <div className="rating-container">
            <StarRating
              rating={product?.rating}
              style={{ margin: 0, padding: 0 }}
            />
          </div>
        )}

        {estimateDeliveryDate && (
          <p className="productDeliveryDate">
            Fecha Estimada de entrega:{' '}
            <span className="bold-text">
              {formatDateSpanish(estimateDeliveryDate)}
            </span>
          </p>
        )}
        {loading ? (
          <TextLoader
            style={{
              alignSelf: 'center',
              width: '100%',
              padding: '0.5rem 2rem',
              fontSize: '16px',
            }}
            texts={['Calculando precio todo incluido']}
          />
        ) : (
          <>
            {salePrice ? (
              <>
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
                <p className="priceMessage">(Precio todo incluido)</p>

                <Button className="primaryBtn" onClick={handleAddToCartModal}>
                  Agregar
                </Button>
              </>
            ) : (
              <Button
                className="primaryBtn"
                style={{ marginTop: '0.5rem' }}
                onClick={() => setProductReportModal(true)}
              >
                Solicitar cotización
              </Button>
            )}
          </>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: productDetails?.link || '',
          title: productDetails?.title || '',
          image: productDetails?.main_image?.link || '',
        }}
      />
      <AmazonAddModal
        isOpen={productAddModal}
        toggle={() => setProductAddModal((prev) => !prev)}
        productDefault={productDetails}
        listTrips={listTrips}
        listDrivers={listDrivers}
        productHistory={productHistory}
        categoryWeights={categoryWeights}
        variantOptions={variantOptions}
        setProductReportModal={setProductReportModal}
      />
    </div>
  );
};

import axios from 'axios';

// Singleton pattern for Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Global cancel token source management
let cancelTokenSource = axios.CancelToken.source();

// Function to create a new cancel token source
export const createNewCancelTokenSource = () => {
  if (cancelTokenSource)
    cancelTokenSource.cancel('Operation canceled due to new request.');
  cancelTokenSource = axios.CancelToken.source();
};

// Function to cancel all requests
export const cancelAllRequests = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('All requests canceled.');
    cancelTokenSource = axios.CancelToken.source();
  }
};

// Set the default cancel token for all requests
axiosInstance.interceptors.request.use((config) => {
  config.cancelToken = cancelTokenSource.token;
  return config;
});

// Add a response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error is a cancellation error
    if (axios.isCancel(error)) {
      console.log('Request was canceled:', error.message);
      // Return a resolved promise to prevent further error handling
      return Promise.resolve(); // Or return null, {}, etc., depending on your needs
    }
    // Handle other types of errors
    handleAxiosError(error);
    return Promise.reject(error);
  },
);

// Centralized error handling function
const handleAxiosError = (error) => {
  // Handle non-cancellation errors (e.g., network errors, server errors)
  console.error('An error occurred:', error.message);
  // Additional error handling logic (e.g., custom error messages, logging)
};

export default axiosInstance;

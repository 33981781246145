import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Sidebar = ({ toggleSidebar, user }) => (
  <div id="sidebar">
    <div id="sidebarContainer">
      <div
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <IoClose onClick={toggleSidebar} />
      </div>
      <div>
        <Link onClick={toggleSidebar} className="navbarItem" to="/">
          Passeio
        </Link>
        {user && (
          <>
            <Link onClick={toggleSidebar} className="navbarItem" to="/orders">
              Pedidos
            </Link>
            {user?.isAdmin && (
              <Link onClick={toggleSidebar} className="navbarItem" to="/admin">
                Admin
              </Link>
            )}
            <Link onClick={toggleSidebar} className="navbarItem" to="/account">
              Cuenta
            </Link>
          </>
        )}
        {!user && (
          <Link onClick={toggleSidebar} className="navbarItem" to="/login">
            Iniciar sesion
          </Link>
        )}
      </div>
    </div>
  </div>
);

export default Sidebar;

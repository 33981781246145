import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../util/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { MdChevronLeft } from 'react-icons/md';
import { Button, Input } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export const AdminCreateDiscountCodePage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setValue, getValues, watch, handleSubmit } = useForm({
    defaultValues: {
      code: '',
      discount: 0,
      minAmount: 0,
      type: '',
      percentage: false,
      singleUse: false,
      status: true,
    },
  });
  const isPercentage = watch('percentage');

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await addDoc(collection(db, 'DiscountCode'), data);
      navigate(-1);
    } catch {
      CustomToast('error', 'Ocurrió un error al crear el código');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Crear Código
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="label">Código</p>
          <Input
            required
            type="text"
            className="mainInput"
            defaultValue={getValues('code')}
            onChange={(e) => setValue('code', e.target.value.toUpperCase())}
            placeholder={`PASSEIO-${new Date().getFullYear()}`}
          />
          <p className="label">Descuento</p>
          <Input
            required
            type="number"
            min={0}
            max={isPercentage ? 100 : 1000}
            step={0.01}
            className="mainInput"
            defaultValue={getValues('discount')}
            onChange={(e) => setValue('discount', e.target.value)}
            placeholder="Descuento a Aplicar"
          />
          <p className="label">Monto mínimo de compra</p>
          <Input
            required
            type="number"
            min={0}
            max={1000}
            step={0.01}
            className="mainInput"
            defaultValue={getValues('minAmount')}
            onChange={(e) => setValue('minAmount', e.target.value)}
            placeholder="Monto Mínimo de Compra"
          />
          <p className="label">Tipo de Descuento</p>
          <Input
            required
            type="select"
            className="mainInput"
            defaultValue={getValues('type')}
            onChange={(e) => setValue('type', e.target.value)}
          >
            <option disabled value="">
              Selecciona un tipo
            </option>
            <option value="product">Producto</option>
            <option value="amazon-shipping">Envio Amazon</option>
            <option value="service">Servicio</option>
            <option value="shipping">Envio</option>
            <option value="total">Total</option>
          </Input>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              marginTop: '0.75rem',
            }}
          >
            <div className="form-check">
              <input
                className="form-check-input text-white"
                type="checkbox"
                defaultChecked={getValues('percentage')}
                onChange={(e) => setValue('percentage', e.target.checked)}
              />
              <label className="form-check-label text-white">
                Es descuento porcentual
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input text-white"
                type="checkbox"
                defaultChecked={getValues('singleUse')}
                onChange={(e) => setValue('singleUse', e.target.checked)}
              />
              <label className="form-check-label text-white">
                Código de un solo uso
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input text-white"
                type="checkbox"
                defaultChecked={getValues('status')}
                onChange={(e) => setValue('status', e.target.checked)}
              />
              <label className="form-check-label text-white">
                Código Activo
              </label>
            </div>
          </div>
          <Button
            type="submit"
            className="primaryBtn"
            style={{ marginTop: '0.5rem' }}
            disabled={loading}
          >
            Crear
          </Button>
        </div>
      </form>
    </div>
  );
};

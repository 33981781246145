export function formatDateISO(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatTime(date) {
  const options = { hour: 'numeric', minute: '2-digit', hour12: true };
  return date.toLocaleTimeString('en-US', options);
}

export function verifiedChangeDate(arrivalDate, dateEdit) {
  const arrivalDateTime = new Date(arrivalDate);
  const modifiedDate = new Date(dateEdit);
  const timeDifference = modifiedDate.getTime() - arrivalDateTime.getTime();
  const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);
  if (differenceInDays > 0) return true;
  else return false;
}

export function parseDate(dateString) {
  try {
    const today = new Date();
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (dateString.includes('Today') || dateString.includes('Overnight'))
      return [today];
    if (dateString.includes('Tomorrow')) {
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return [tomorrow];
    }
    if (dateString.includes(' - ')) {
      const [start, end] = dateString
        .split(' - ')
        .map((part) => part.split(' '));
      const [startMonth, startDay] = [months[start[0]], parseInt(start[1])];
      const [endMonth, endDay] = [
        months[end[0]] || startMonth,
        parseInt(end[1] || end[0]),
      ];
      const startYear = today.getFullYear();
      const endYear = startMonth > endMonth ? startYear + 1 : startYear;
      const startDate = new Date(startYear, startMonth, startDay);
      const endDate = new Date(endYear, endMonth, endDay);
      if (isValidDate(startDate) && isValidDate(endDate))
        return [startDate, endDate];
    }
    const [, datePart] = dateString.split(', ');
    const [monthName, day] = datePart.split(' ');
    const date = new Date(
      today.getFullYear(),
      months[monthName],
      parseInt(day),
    );
    if (isValidDate(date)) return [date];
  } catch {
    return [];
  }
}

export function formatDateSpanish(fecha) {
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const dayWeek = days[fecha.getDay()];
  const dayMonth = fecha.getDate();
  const month = months[fecha.getMonth()];
  return `${dayWeek}, ${dayMonth} de ${month}`;
}

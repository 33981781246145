import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { Button, Input } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export const AdminEditCommonSearchPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { setValue, getValues, handleSubmit } = useForm();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      try {
        const response = await firebaseService.findOneCommonSearch(id);
        setValue('id', response[0].id);
        setValue('type', response[0].type);
        setValue('value', decodeURIComponent(response[0].value));
        setValue('status', response[0].status);
      } catch {
        CustomToast('error', 'No se pudo cargar la información de la busqueda');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await firebaseService.updateCommonSearch(data.id, {
        type: data.type,
        value: data.value.toLowerCase(),
        status: data.status,
      });
      navigate(-1);
    } catch {
      CustomToast('error', 'No se pudo actualizar la busqueda');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Actualizando Busqueda Frecuente
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">ID</p>
        <Input
          disabled
          type="text"
          className="mainInput"
          placeholder="123456"
          defaultValue={getValues('id')}
          onChange={(e) => setValue('id', e.target.value)}
        />

        <p className="label">Termino</p>
        <Input
          required
          type="text"
          className="mainInput"
          placeholder="Computadoras"
          defaultValue={getValues('value')}
          onChange={(e) => setValue('value', e.target.value)}
        />

        <p className="label">Tipo</p>
        <Input
          required
          type="select"
          className="mainInput"
          placeholder="Computadoras"
          defaultValue={getValues('type')}
          onChange={(e) => setValue('type', e.target.value)}
        >
          <option disabled value="">
            Selecciona un tipo
          </option>
          <option value="search">Busqueda</option>
          <option value="url">URL</option>
          <option value="asin">ASIN</option>
        </Input>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input text-white"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked={getValues('status')}
              onChange={(e) => setValue('status', e.target.checked)}
            />
            <label
              className="form-check-label text-white"
              htmlFor="flexCheckChecked"
            >
              Busqueda activa
            </label>
          </div>
        </div>

        <Button
          type="submit"
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
          disabled={isLoading}
        >
          Actualizar
        </Button>
      </form>
    </div>
  );
};

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export const ProductNotFound = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <Row>
        <Col>
          <h1 className="text-center mb-4">
            404 - El producto que buscas no ha sido encontrado
          </h1>
          <p className="text-center">
            Lo siento, el producto con el ID {id} no se pudo encontrar.
          </p>
          <div className="d-flex justify-content-center mt-4">
            <Button onClick={() => navigate(-1)} variant="primary">
              Regresar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

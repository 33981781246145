export function isAmazonUrl(url) {
  const amazonRegex =
    /^(https?:\/\/)?(www\.)?amazon\.(com|ca|co\.uk|de|fr|es|it|nl|com\.au|com\.mx|in|ae|com\.tr|com\.br|sg|se|pl|co\.jp|com\.sa|com\.hk|com\.sg|com\.my|com\.ph|com\.vn|nl|com\.eg|com\.tr|com\.sa|com\.hk|com\.sg|com\.au|com\.my|com\.ph|com\.vn|com\.mx|co\.id|com\.tr|com\.sa|com\.hk|com\.sg|com\.my|com\.ph|com\.vn|co\.id|com\.tr|com\.sa|com\.hk|com\.sg|com\.my|com\.ph|com\.vn|com\.mx|com\.tr|com\.sa|com\.hk|com\.sg|com\.my|com\.ph|com\.vn|co\.id|com\.tr|com\.sa|com\.hk|com\.sg|com\.my|com\.ph|com\.vn)\/.*$/;
  return amazonRegex.test(url);
}

export function cleanAmazonUrl(url) {
  return url.replace(/\/-\/\w{2}|&language=es_US/g, '');
}

export function isSheinUrl(url) {
  const sheinRegex = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)?shein\.com\/.*$/;
  return sheinRegex.test(url);
}

import './StarRating.css';
import React from 'react';
import { PiStarFill } from 'react-icons/pi';

const StarRating = ({
  rating,
  totalStars = 5,
  size = 20,
  filledColor = 'gold',
  emptyColor = 'gray',
  style = {},
}) => {
  return (
    <div className="star-rating" style={{ display: 'flex' }}>
      {[...Array(totalStars)].map((_, index) => {
        const starNumber = index + 1;
        const fillPercentage = Math.max(0, Math.min(1, rating - index)) * 100;

        return (
          <div
            key={starNumber}
            style={{
              position: 'relative',
              display: 'inline-block',
              width: size,
              height: size,
              marginLeft: '0.25rem',
              marginRight: '0.25rem',
            }}
          >
            <PiStarFill
              style={{
                width: size,
                height: size,
                color: emptyColor,
                position: 'absolute',
                top: 0,
                left: 0,
                ...style,
              }}
            />
            <PiStarFill
              style={{
                width: size,
                height: size,
                color: filledColor,
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'hidden',
                clipPath: `inset(0 ${100 - fillPercentage}% 0 0)`,
                ...style,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;

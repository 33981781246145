export const getItemIdKey = (source) => {
  switch (source) {
    case 'amazon':
      return 'asin';
    case 'shein':
      return 'goods_id';
    default:
      return null;
  }
};

/**
 * @param {*} id
 * @returns {string} Product store
 * @description Identify the store of the product based on the product ID
 * @example
 * identifyProductID('B0BXFRRLHD') // 'Amazon'
 * identifyProductID('15153695') // 'Shein'
 * identifyProductID('1234567890') // 'Unknown ID'
 * @returns {string} Product store
 */
export const identifyProductID = (id) => {
  const isAmazonId = /^[A-Z0-9]{10}$/.test(id);
  const isSheinId = /^[0-9]{7,10}$/.test(id);
  if (isAmazonId) return 'amazon';
  if (isSheinId) return 'shein';
  return 'unknown';
};

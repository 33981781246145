import { useState, useEffect } from 'react';

import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';

import {
  query,
  getDocs,
  collection,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { auth, db } from '../../../util/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Button } from 'reactstrap';
import { MdChevronLeft } from 'react-icons/md';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminCreateTravelerPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      let fetchedUsers = [];
      const usersRef = collection(db, 'Users');
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data();
        if (newUser?.uid !== user?.uid) {
          fetchedUsers.push(newUser);
        }
      });
      setUsers(fetchedUsers);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los viajeros');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [user]);

  const [selectedUser, setSelectedUser] = useState();

  const addTraveler = async () => {
    Swal.fire({
      text: '¿Estas segur@ que quieres agregar a este viajero?',
      icon: 'question',
      showCancelButton: true,
      background: '#111111',
      confirmButtonColor: '#FFD000',
      cancelButtonColor: '#555555',
      confirmButtonText: 'Si, agregar',
      cancelButtonText: 'No, cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const userRef = query(
            collection(db, 'Users'),
            where('uid', '==', selectedUser?.uid),
          );
          const findUsers = await getDocs(userRef);
          findUsers.forEach(async (user) => {
            const getUser = doc(db, 'Users', user?.id);
            await updateDoc(getUser, {
              type: 'driver',
              driverStatus: 'Activo',
              driverCapacity: [],
            });
          });
          navigate(-1);
        } catch {
          CustomToast('error', 'No se pudo agregar al viajero');
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  if (isLoading) return <PageLoader />;

  return (
    <div className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Agregar viajero
      </h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item, index) => {
              if (selectedUser) {
                if (item === selectedUser) {
                  return (
                    <tr key={index} onClick={() => setSelectedUser()}>
                      <td data-title="ID">{item?.customer_id || 'N/A'}</td>
                      <td data-title="Nombre">
                        {item?.firstName + ' ' + item?.lastName}
                      </td>
                      <td data-title="Correo">{item?.email}</td>
                      <td data-title="Estado">
                        {item?.aproved ? 'Aprovado' : 'Pendiente'}
                      </td>
                    </tr>
                  );
                }
              } else {
                return (
                  <tr key={index} onClick={() => setSelectedUser(item)}>
                    <td data-title="ID">{item?.customer_id || 'N/A'}</td>
                    <td data-title="Nombre">
                      {item?.firstName + ' ' + item?.lastName}
                    </td>
                    <td data-title="Correo">{item?.email}</td>
                    <td data-title="Estado">
                      {item?.aproved ? 'Aprovado' : 'Pendiente'}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        {selectedUser ? (
          <Button
            onClick={addTraveler}
            className="primaryBtn"
            style={{ marginTop: '0.5rem' }}
          >
            Agregar
          </Button>
        ) : null}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export default function AdminCommonSearchPage() {
  const [commonSearches, setCommonSearches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const sorted = commonSearches.sort((a, b) => {
      if (a.status && !b.status) {
        return -1;
      }
      if (!a.status && b.status) {
        return 1;
      }
      return 0;
    });
    setCommonSearches(sorted);
  }, [commonSearches]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebaseService.findCommonSearches();
        if (searchQuery.trim() !== '') {
          const filteredItems = response.filter(
            (item) =>
              item.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.type.toLowerCase().includes(searchQuery.toLowerCase()),
          );
          setCommonSearches(filteredItems);
        } else {
          setCommonSearches(response);
        }
      } catch {
        CustomToast(
          'error',
          'No se pudo cargar la información de las busquedas comunes',
        );
      }
    };

    fetchData();
  }, [searchQuery]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = commonSearches.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(value) => setSearchQuery(value.target.value)}
        />
        <Button
          onClick={() => navigate('/common-search/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Termino</th>
            <th>Tipo</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              onClick={() => navigate(`/common-search/${item.id}`)}
            >
              <td data-title="ID">{item.id}</td>
              <td data-title="Termino">{item.value}</td>
              <td data-title="Tipo">{item.type}</td>
              <td data-title="Estado">{item.status ? 'Activo' : 'Inactivo'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(commonSearches.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
}

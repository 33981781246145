import React from 'react';
import { Modal, Input, Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';

const CreateNoteModal = ({ isOpen, toggle, value, changeComment }) => {
  const [comment, setComment] = React.useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    changeComment(value?.id, comment);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <IoClose onClick={toggle} />
        </div>
        <h3 className="cardHeader">Editar notas de la orden</h3>
        <form onSubmit={onSubmit}>
          <p className="label">Notas de la orden</p>
          <Input
            required
            type="textarea"
            className="mainInput"
            style={{ height: '200px' }}
            defaultValue={value?.comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button type="submit" className="primaryBtn">
            Guardar
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default CreateNoteModal;

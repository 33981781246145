import { collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { db } from '../../../util/firebase';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export const AdminDiscountCodesPage = () => {
  const navigate = useNavigate();
  const [discountCodes, setDiscountCodes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const sorted = discountCodes.sort((a, b) => {
      if (a.status && !b.status) {
        return -1;
      }
      if (!a.status && b.status) {
        return 1;
      }
      return 0;
    });
    setDiscountCodes(sorted);
  }, [discountCodes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryRef = collection(db, 'DiscountCode');
        const q = query(categoryRef);
        const querySnapshot = await getDocs(q);
        const listCode = [];
        querySnapshot.forEach((doc) => {
          const category = doc.data();
          listCode.push({
            ...category,
            id: doc.id,
          });
        });
        if (searchQuery.trim() !== '') {
          const filteredCodes = listCode.filter((code) =>
            code.code.toLowerCase().includes(searchQuery.toLowerCase()),
          );
          setDiscountCodes(filteredCodes);
        } else {
          setDiscountCodes(listCode);
        }
      } catch {
        CustomToast(
          'error',
          'No se pudo cargar la información de los códigos de descuento',
        );
      }
    };

    fetchData();
  }, [searchQuery]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = discountCodes.slice(
    indexOfFirstItem,
    indexOfFirstItem + itemsPerPage,
  );

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(value) => setSearchQuery(value.target.value)}
        />
        <Button
          onClick={() => navigate('/discount-code/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Codigo</th>
            <th>Descuento</th>
            <th>Tipo</th>
            <th>Un uso</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              onClick={() => navigate(`/discount-code/${item.id}`)}
            >
              <td data-title="Codigo">{item.code}</td>
              <td data-title="Descuento">
                {!item.percentage && '$'}
                {item.discount}
                {item.percentage && '%'}
              </td>
              <td data-title="Tipo">{item.type || 'N/A'}</td>
              <td data-title="Un uso">{item.singleUse ? 'Si' : 'No'}</td>
              <td data-title="Estado">{item.status ? 'Activo' : 'Inactivo'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(discountCodes.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
};

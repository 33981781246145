export const getDiscountTypeDescription = (type) => {
  switch (type) {
    case 'product':
      return 'Producto';
    case 'shipping':
      return 'Servicio';
    case 'total':
      return 'Total';
    default:
      return '';
  }
};

import axiosInstance from '../util/axios-req';

export default class EmailService {
  static instance;

  static getInstance() {
    EmailService.instance ??= new EmailService();
    return EmailService.instance;
  }

  async send(payload) {
    const response = await axiosInstance.post(`/email`, payload);
    return response.status === 201;
  }
}

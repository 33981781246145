export const formatNumberWithMoney = (x) => {
  const number = x.toFixed(2);
  const str = number.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
};

export function cleanPhone(phone) {
  return phone.replace(/[-\s]/g, '');
}

import './SourceButtons.css';
import React from 'react';
import { Button } from 'reactstrap';

const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context('../../assets/svg', false, /\.(png|jpe?g|svg)$/),
);

export function SourceButtons({ options, value, setValue }) {
  const getImageSrc = (value) => {
    const image = images.find((o) => o.includes(value));
    return image ? image : '';
  };

  return (
    <div id="sourceBtnContainer">
      {options.map((item, index) => (
        <Button
          className={`sourceBtn ${value === item.value ? 'active' : ''}`}
          key={index}
          value={item.value}
          onClick={() => setValue('source', item.value)}
        >
          {item.label}
          <img
            src={getImageSrc(item.value)}
            alt={item.label}
            className="sourceIcon"
          />
        </Button>
      ))}
    </div>
  );
}

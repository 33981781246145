import React from 'react';
import { Modal, Input, Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';

const PayByCardModal = ({
  isOpen,
  toggle,
  cardNumber,
  setCardNumber,
  cardExpiryMonth,
  setCardExpiryMonth,
  cardExpiryYear,
  setCardExpiryYear,
  cardCvc,
  setCardCvc,
  generatePay,
  loading,
  loadingPaymentModal,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <IoClose onClick={toggle} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <FaCcVisa
            style={{
              width: 40,
              height: 40,
              marginRight: '1rem',
              color: '#FFFFFF',
              cursor: 'default',
            }}
          />
          <FaCcMastercard
            style={{
              width: 40,
              height: 40,
              color: '#FFFFFF',
              cursor: 'default',
            }}
          />
        </div>
        <div>
          <p className="label">Número de tarjeta</p>
          <Input
            type="number"
            className="mainInput"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="El número de la tarjeta"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '49%' }}>
              <p className="label">Vencimiento</p>
              <div id="cardExpiryInput">
                <Input
                  required
                  type="number"
                  min={0}
                  step={1}
                  max={12}
                  value={cardExpiryMonth}
                  onChange={(e) => setCardExpiryMonth(e.target.value)}
                  placeholder="Mes"
                />
                <p>/</p>
                <Input
                  required
                  type="number"
                  min={new Date().getFullYear()}
                  step={1}
                  max={9999}
                  value={cardExpiryYear}
                  onChange={(e) => setCardExpiryYear(e.target.value)}
                  placeholder="Año"
                />
              </div>
            </div>
            <div style={{ width: '49%' }}>
              <p className="label">CVC</p>
              <Input
                required
                type="number"
                min={0}
                step={1}
                max={999}
                className="mainInput"
                value={cardCvc}
                onChange={(e) => setCardCvc(e.target.value)}
                placeholder="El CVC de la tarjeta"
              />
            </div>
          </div>
          <Button
            disabled={loading || loadingPaymentModal}
            onClick={generatePay}
            className="primaryBtn"
            style={{ marginTop: '1.25rem' }}
          >
            Completar pago
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PayByCardModal;

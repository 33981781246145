import { toast } from 'react-toastify';
import slugify from 'slugify';

const defaultOptions = {
  position: 'top-right',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'dark',
};

export const CustomToast = (type = 'default', message, options = {}) => {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    toastId: slugify(message, { lower: true }),
  };

  switch (type) {
    case 'info':
      toast.info(message, mergedOptions);
      break;
    case 'success':
      toast.success(message, mergedOptions);
      break;
    case 'warning':
      toast.warn(message, mergedOptions);
      break;
    case 'error':
      toast.error(message, mergedOptions);
      break;
    default:
      toast(message, mergedOptions);
      break;
  }
};

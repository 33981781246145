import { getItemIdKey } from './sources';

export const isItemValid = (item) => {
  if (!item.ttl || !item.createdAt) return false;
  const now = Date.now();
  return item.createdAt + item.ttl > now;
};

export const itemExistsInCart = (cartItems, item) => {
  const key = getItemIdKey(item?.source);
  if (!key) return false;
  return cartItems.some((dbItem) => item[key] === dbItem[key]);
};

export const removeItemFromCart = (cartItems, item) => {
  const key = getItemIdKey(item?.source);
  if (!key) return cartItems;
  return cartItems.filter((dbItem) => item[key] !== dbItem[key]);
};

export const mergeCartItems = (dbItems = [], localItems = []) => {
  const validDbItems = dbItems.filter(isItemValid);
  const validLocalItems = localItems.filter(isItemValid);
  return validLocalItems.reduce((acc, item) => {
    if (!itemExistsInCart(acc, item)) {
      acc.push(item);
    }
    return acc;
  }, validDbItems);
};

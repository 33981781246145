import React from 'react';
import DiscountCodeInput from '../../Inputs/DiscountCodeInput/DiscountCodeInput';
import { getDiscountTypeDescription } from '../../../util/discount';

const DiscountCodeSection = ({
  infoDiscount,
  discountCode,
  setDiscountCode,
  loadingCode,
  verifyDiscountCode,
  clearDiscountCode,
  activeDeliveryOption,
  discountOrder,
}) => {
  return (
    <div id="breakdownDetails">
      <p className="cardHeader">Código de descuento</p>
      <div>
        <DiscountCodeInput
          infoDiscount={infoDiscount}
          discountCode={discountCode}
          setDiscountCode={setDiscountCode}
          loadingCode={loadingCode}
          verifyDiscountCode={verifyDiscountCode}
          clearDiscountCode={clearDiscountCode}
        />
        {infoDiscount && (
          <div className="flexRowSpaceBetween">
            {!(
              infoDiscount.type === 'shipping' && activeDeliveryOption !== 2
            ) ? (
              <>
                <p>{`Descuento (${getDiscountTypeDescription(infoDiscount.type)} ${infoDiscount.percentage ? `${infoDiscount.discount}%` : `$${infoDiscount.discount}`}):`}</p>
                <p>{`-$${discountOrder.discount.toFixed(2) > 0 ? discountOrder.discount.toFixed(2) : 0}`}</p>
              </>
            ) : (
              <p>Descuento unicamente aplicable a envíos a domicilio.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountCodeSection;

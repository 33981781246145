import React from 'react';
import { BarLoader } from 'react-spinners';
import './PageLoader.css';

export default function PageLoader() {
  return (
    <div className="loadingPage">
      <BarLoader color="#FFD000" />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { ToastContainer } from 'react-toastify';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import { useForm } from 'react-hook-form';
import logo from '../../../assets/img/logos/col.png';
import { useAuth } from '../../../hooks/useAuth';
import { auth } from '../../../util/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import PasswordInput from '../../../components/Inputs/PasswordInput/PasswordInput';

export function LoginPage() {
  const [loading, setLoading] = useState(false);
  const { watch, setValue, handleSubmit } = useForm();
  const { checkout, setCheckout, profile, localUser, user } = useAuth();
  const navigate = useNavigate();
  const storeWatcher = watch();

  useEffect(() => {
    if (profile || localUser || user) {
      navigate(checkout ? '/checkout' : '/', { replace: true });
      setCheckout(false);
    }
  }, [profile, navigate]);

  const onSubmit = async (data) => {
    if (!data.email || !data.password) {
      CustomToast('error', 'Asegurate de llenar todos los campos');
      return;
    }
    if (data.password.length < 6) {
      CustomToast('error', 'La contraseña debe tener al menos 6 caracteres');
      return;
    }
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, data.email, data.password).then(
        (res) => {
          localStorage.setItem('user', JSON.stringify(res.user));
          CustomToast('success', 'Inicio de sesion exitoso');
          navigate(checkout ? '/checkout' : '/', { replace: true });
          setCheckout(false);
        },
      );
    } catch {
      CustomToast('error', 'Credenciales incorrectas');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="loginPage">
      <Link to="/" className="back-arrow">
        <IoIosArrowRoundBack size={50} color="#fecf41" />
      </Link>
      <div className="loginContainer">
        <img
          alt="logo"
          src={logo}
          style={{
            width: 200,
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className="authLabel">Correo electrónico</label>
            <Input
              type="email"
              className="mainInput"
              placeholder="johndoe@example.com"
              onChange={(e) => setValue('email', e.target.value)}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="authLabel">Contraseña</label>
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'flex-start',
                }}
              >
                <PasswordInput
                  value={storeWatcher.password}
                  onChange={(e) => setValue('password', e.target.value)}
                />
              </div>
            </div>
          </div>
          <Button type="submit" className="primaryBtn" disabled={loading}>
            Iniciar sesion
          </Button>
          <Link className="authLink" to="/reset">
            ¿Olvidaste tu contraseña?
          </Link>
          <p>
            ¿No tienes cuenta?{' '}
            <Link className="authLink" to="/register">
              Crea tu cuenta
            </Link>{' '}
            aqui.
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

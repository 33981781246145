import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';

// Create a context for all images in the banners directory
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context('../../assets/img/banners', false, /\.(png|jpe?g|svg)$/),
);

// Map images to the format required by UncontrolledCarousel
const items = images.map((src, index) => ({
  key: index + 1,
  src,
}));

export default function BannerCarousel() {
  return <UncontrolledCarousel pause={false} items={items} />;
}

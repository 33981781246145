import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export default function AdminCategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const sorted = categories.sort((a, b) => {
      if (a.status && !b.status) {
        return -1;
      }
      if (!a.status && b.status) {
        return 1;
      }
      return 0;
    });
    setCategories(sorted);
  }, [categories]);

  useEffect(() => {
    searchCategories();
  }, [searchQuery]);

  const searchCategories = async () => {
    try {
      const response = await firebaseService.findCategoryWeights();
      if (searchQuery.trim() !== '') {
        const filteredCategories = response.filter(
          (category) =>
            category.category_id
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            category.name.toLowerCase().includes(searchQuery.toLowerCase()),
        );
        setCategories(filteredCategories);
      } else {
        setCategories(response);
      }
    } catch {
      CustomToast(
        'error',
        'No se pudo cargar la información de las categorías',
      );
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(value) => setSearchQuery(value.target.value)}
        />
        <Button
          onClick={() => navigate('/category/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre</th>
            <th>Peso</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              onClick={() => navigate(`/category/${item.category_id}`)}
            >
              <td data-title="ID">{item.category_id}</td>
              <td data-title="Nombre">{item.name}</td>
              <td data-title="Peso">{item.weight}</td>
              <td data-title="Estado">{item.status ? 'Activo' : 'Inactivo'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(categories.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
}

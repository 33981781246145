import { useState } from 'react';
import { ReactImageCarouselViewer } from 'react-image-carousel-viewer';
import './ImageViewer.css';

function ImageViewer({ images = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(0);

  return (
    <div className="img-container">
      <div className="img-row">
        {images.map((item, idx) => (
          <img
            className={`img-row-item ${active === idx ? 'active' : ''}`}
            src={item?.src ?? ''}
            alt={item?.description ?? ''}
            key={idx}
            onClick={() => setActive(idx)}
          />
        ))}
      </div>

      <div className="img-overlay">
        <img
          className="img-overlay-item"
          src={images[active]?.src ?? ''}
          alt={images[active]?.description ?? ''}
          onClick={() => {
            setIndex(active);
            setIsOpen(true);
          }}
        />
      </div>

      <ReactImageCarouselViewer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        images={images}
        startIndex={index}
      />
    </div>
  );
}

export default ImageViewer;

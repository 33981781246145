import { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  deleteDoc,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useAuth } from '../../../hooks/useAuth';
import AdminUsersPage from '../AdminUsersPage/AdminUsersPage';
import AdminServicesPage from '../AdminServicesPage/AdminServicesPage';
import AdminProductsPage from '../AdminProductsPage/AdminProductsPage';
import AdminOrdersPage from '../AdminOrdersPage/AdminOrdersPage';
import AdminTravelersPage from '../AdminTravelersPage/AdminTravelersPage';
import { AdminRequestPage } from '../AdminRequestPage/AdminRequestPage';
import { AdminRecomendationsPage } from '../AdminRecomendationsPage/AdminRecomendationsPage';
import { AdminDiscountCodesPage } from '../AdminDiscountCodesPage/AdminDiscountCodesPage';
import AdminCategoriesPage from '../AdminCategoriesPage/AdminCategoriesPage';
import AdminCommonSearchPage from '../AdminCommonSearchesPage/AdminCommonSearchesPage';
import { CustomToast } from '../../../components/Notifications/CustomToast';
require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

const tabs = [
  { id: 1, label: 'Usuarios' },
  { id: 2, label: 'Servicios' },
  { id: 3, label: 'Productos' },
  { id: 4, label: 'Pedidos' },
  { id: 5, label: 'Viajeros' },
  { id: 6, label: 'Solicitudes' },
  { id: 7, label: 'Recomendaciones' },
  { id: 8, label: 'Cupones' },
  { id: 9, label: 'Categorías' },
  { id: 10, label: 'Busquedas Frecuentes' },
];

export function AdminPage() {
  // STATES
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [searchedUser, setSearchedUser] = useState('');
  const [services, setServices] = useState([]);
  const [statusFilter, setStatusFilter] = useState();
  const [customerNameFilter, setCustomerNameFilter] = useState();
  const [driverNameFilter, setDriverNameFilter] = useState();
  const [deliveryDateFilter, setDeliveryDateFilter] = useState();
  const [deliveryDateFromFilter, setDeliveryDateFromFilter] = useState();
  const [deliveryDateUntilFilter, setDeliveryDateUntilFilter] = useState();
  const [isPaidFilter, setisPaidFilter] = useState();
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [filteredServices, setFilteredServices] = useState();
  const [activeDateFilterOption, setActiveDateFilterOption] = useState(1);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState();
  const [searchedOrder] = useState('');
  const [activeDriversTab, setActiveDriversTab] = useState(2);
  const [travelerToSchedule, setTravelerToSchedule] = useState();
  const [dateToSchedule, setDateToSchedule] = useState();
  const [scheduleTravelerModalIsVisible, setScheduleTravelerModalIsVisible] =
    useState(false);
  const [events, setEvents] = useState([]);
  const [activeCalendarEvent, setActiveCalendarEvent] = useState();
  const [calendarEventModalIsVisible, setCalendarEventModalIsVisible] =
    useState(false);

  // HOOKS
  const navigate = useNavigate();
  const { profile } = useAuth();

  useEffect(() => {
    fetchUsers();
    fetchServices();
    fetchProducts();
    fetchOrders();
    fetchEvents();
  }, []);

  useEffect(() => {
    if (!activeTab) return;

    window.history.replaceState({}, '', `?tab=${activeTab}`);
  }, [activeTab]);

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const tabURL = url.get('tab');

    if (tabURL) {
      setActiveTab(Number(tabURL));
    } else {
      setActiveTab(1);
    }
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      let fetchedUsers = [];
      let fetchedDrivers = [];
      const usersRef = collection(db, 'Users');
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data();
        if (newUser?.type === 'driver' && newUser?.uid !== profile?.uid)
          fetchedDrivers.push(newUser);
        fetchedUsers.push(newUser);
      });
      setUsers(fetchedUsers);
      setDrivers(fetchedDrivers);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los usuarios');
    } finally {
      setIsLoading(false);
    }
  };

  const listUser = useMemo(() => {
    if (!users) return [];

    return users.filter((profile) => {
      const firstName = profile?.firstName || '';
      const lastName = profile?.lastName || '';
      const email = profile?.email || '';

      return (
        email.toLowerCase().includes(searchedUser) ||
        firstName.toLowerCase().includes(searchedUser?.toLowerCase()) ||
        lastName.toLowerCase().includes(searchedUser?.toLowerCase())
      );
    });
  }, [users, searchedUser]);

  const fetchServices = async () => {
    try {
      setIsLoading(true);
      let fetchedServices = [];
      const servicesRef = collection(db, 'Services');
      const q = query(servicesRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newService = doc.data();
        fetchedServices.push(newService);
      });
      const sortedServices = fetchedServices.sort(
        (objA, objB) => Number(objB.dateCreated) - Number(objA.dateCreated),
      );
      setServices(sortedServices);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los servicios');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAreFiltersVisible = () => {
    setAreFiltersVisible(!areFiltersVisible);
  };

  const applyFilters = () => {
    let newServices = services;
    if (statusFilter) {
      newServices = newServices.filter((service) => {
        return service.status === statusFilter;
      });
    }
    if (customerNameFilter) {
      newServices = newServices.filter((service) => {
        return service.senderName
          .toLowerCase()
          ?.includes(customerNameFilter.toLowerCase());
      });
    }
    if (driverNameFilter)
      newServices = newServices.filter((service) => {
        return service.driverName
          ?.toLowerCase()
          ?.includes(driverNameFilter.toLowerCase());
      });
    if (activeDateFilterOption === 1)
      if (deliveryDateFilter)
        newServices = newServices.filter((service) => {
          return (
            service.arrivalDate.toDate().toDateString() ===
            new Date(deliveryDateFilter).toDateString()
          );
        });
      else if (activeDateFilterOption === 2) {
        if (deliveryDateFromFilter && deliveryDateUntilFilter)
          newServices = newServices.filter((service) => {
            const start = Date.parse(deliveryDateFromFilter);
            const end = Date.parse(deliveryDateUntilFilter);
            const d = Date.parse(service.arrivalDate.toDate());
            return d >= start && d <= end;
          });
      }
    if (isPaidFilter)
      newServices = newServices.filter((service) => {
        return service.payed.toString() === isPaidFilter;
      });
    setFilteredServices(newServices);
  };

  const clearFilters = () => {
    setStatusFilter('');
    setCustomerNameFilter('');
    setDriverNameFilter('');
    setDeliveryDateFilter('');
    setDeliveryDateFromFilter('');
    setDeliveryDateUntilFilter('');
    setisPaidFilter('');
    setFilteredServices();
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      let fetchedProducts = [];
      const productsRef = collection(db, 'historyProduct');
      const q = query(productsRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newProduct = doc.data();
        fetchedProducts.push({
          ...newProduct,
          uid: doc.id,
        });
      });
      const sortedProducts = fetchedProducts.sort(
        (objA, objB) => Number(objB.dateCreated) - Number(objA.dateCreated),
      );
      setProducts(sortedProducts);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los productos');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      let fetchedOrders = [];
      const ordersRef = collection(db, 'Orders');
      const q = query(ordersRef, orderBy('dateCreated', 'desc'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newOrder = doc.data();
        fetchedOrders.push({
          ...newOrder,
          id: doc.id,
        });
      });
      setOrders(fetchedOrders);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los pedidos');
    } finally {
      setIsLoading(false);
    }
  };

  const listOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => {
      return (
        order.id.includes(searchedOrder) ||
        order.customerName.toLowerCase().includes(searchedOrder.toLowerCase())
      );
    });
  }, [orders, searchedOrder]);

  const toggleScheduleTravelerModal = (date) => {
    setTravelerToSchedule();
    setDateToSchedule();
    setScheduleTravelerModalIsVisible(!scheduleTravelerModalIsVisible);
  };

  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      let fetchedEvents = [];
      const eventsRef = collection(db, 'Trips');
      const q = query(eventsRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newEvent = doc.data();
        const newEventObject = {
          title: newEvent.travelerName,
          start: moment(newEvent.date).toDate(),
          end: moment(newEvent.date).toDate(),
        };
        fetchedEvents.push(newEventObject);
      });
      setEvents(fetchedEvents);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los viajeros');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCalendarEventModal = (event) => {
    if (calendarEventModalIsVisible) {
      setCalendarEventModalIsVisible(false);
    } else {
      setActiveCalendarEvent();
      setActiveCalendarEvent(event);
      setCalendarEventModalIsVisible(true);
    }
  };

  const scheduleTraveler = async () => {
    const travelerObject = users.find((obj) => {
      return obj.uid === travelerToSchedule;
    });
    const newTrip = {
      uid: uuidv4(),
      travelerName: travelerObject.firstName + ' ' + travelerObject.lastName,
      travelerUid: travelerToSchedule,
      date: new Date(dateToSchedule).toISOString(),
    };
    await addDoc(collection(db, 'Trips'), newTrip);
    fetchEvents();
    toggleScheduleTravelerModal();
    if (calendarEventModalIsVisible) toggleCalendarEventModal();
  };

  const deleteCalendarEvent = async (event) => {
    Swal.fire({
      text: '¿Estas segur@ que quieres eliminar a este viajero de esta fecha?',
      icon: 'question',
      showCancelButton: true,
      background: '#111111',
      confirmButtonColor: '#FFD000',
      cancelButtonColor: '#555555',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const tripRef = query(
            collection(db, 'Trips'),
            where('travelerName', '==', event.title),
            where('date', '==', new Date(event.start).toISOString()),
          );
          const findTrips = await getDocs(tripRef);
          findTrips.forEach(async (trip) => {
            const getTrip = doc(db, 'Trips', trip.id);
            await deleteDoc(getTrip);
          });
          fetchEvents();
          toggleCalendarEventModal();
        } catch {
          CustomToast('error', 'No se pudo eliminar el viajero');
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const redirectDetailsOrder = (e, order) => {
    e.stopPropagation();
    navigate(`/orders/${order.id}`);
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="adminPage" className="page">
      <h2>Admin</h2>
      <ScrollMenu>
        {tabs.map((item, index) => (
          <Button
            key={index}
            onClick={() => setActiveTab(item.id)}
            className={classnames('tabButton', {
              active: activeTab === item.id,
            })}
          >
            {item.label}
          </Button>
        ))}
      </ScrollMenu>
      <div style={{ marginTop: '1rem' }}>
        {activeTab === 1 && (
          <AdminUsersPage
            listUser={listUser}
            setSearchedUser={setSearchedUser}
          />
        )}
        {activeTab === 2 && (
          <AdminServicesPage
            // Filtros
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            customerNameFilter={customerNameFilter}
            setCustomerNameFilter={setCustomerNameFilter}
            driverNameFilter={driverNameFilter}
            setDriverNameFilter={setDriverNameFilter}
            deliveryDateFilter={deliveryDateFilter}
            setDeliveryDateFilter={setDeliveryDateFilter}
            deliveryDateFromFilter={deliveryDateFromFilter}
            setDeliveryDateFromFilter={setDeliveryDateFromFilter}
            deliveryDateUntilFilter={deliveryDateUntilFilter}
            setDeliveryDateUntilFilter={setDeliveryDateUntilFilter}
            isPaidFilter={isPaidFilter}
            setisPaidFilter={setisPaidFilter}
            activeDateFilterOption={activeDateFilterOption}
            setActiveDateFilterOption={setActiveDateFilterOption}
            // Estado y funciones de los filtros
            areFiltersVisible={areFiltersVisible}
            toggleAreFiltersVisible={toggleAreFiltersVisible}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            // Servicios
            services={services}
            filteredServices={filteredServices}
          />
        )}
        {activeTab === 3 && <AdminProductsPage products={products} />}
        {activeTab === 4 && (
          <AdminOrdersPage
            listOrders={listOrders}
            redirectDetailsOrder={redirectDetailsOrder}
          />
        )}
        {activeTab === 5 && (
          <AdminTravelersPage
            activeDriversTab={activeDriversTab}
            setActiveDriversTab={setActiveDriversTab}
            scheduleTravelerModalIsVisible={scheduleTravelerModalIsVisible}
            toggleScheduleTravelerModal={toggleScheduleTravelerModal}
            dateToSchedule={dateToSchedule}
            setDateToSchedule={setDateToSchedule}
            travelerToSchedule={travelerToSchedule}
            setTravelerToSchedule={setTravelerToSchedule}
            scheduleTraveler={scheduleTraveler}
            localizer={localizer}
            events={events}
            toggleCalendarEventModal={toggleCalendarEventModal}
            calendarEventModalIsVisible={calendarEventModalIsVisible}
            activeCalendarEvent={activeCalendarEvent}
            drivers={drivers}
            deleteCalendarEvent={deleteCalendarEvent}
          />
        )}
        {activeTab === 6 && <AdminRequestPage users={users} />}
        {activeTab === 7 && <AdminRecomendationsPage users={users} />}
        {activeTab === 8 && <AdminDiscountCodesPage users={users} />}
        {activeTab === 9 && <AdminCategoriesPage />}
        {activeTab === 10 && <AdminCommonSearchPage />}
      </div>
    </div>
  );
}

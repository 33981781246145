import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { identifyProductID } from '../../../util/sources';
import AmazonDetail from '../../../components/Products/Detail/Amazon/AmazonDetail';
import SheinDetail from '../../../components/Products/Detail/Shein/SheinDetail';

export default function ProductDetailPage() {
  const { id } = useParams();
  const store = useMemo(() => identifyProductID(id), [id]);

  const detailComponents = {
    amazon: <AmazonDetail asin={id} />,
    shein: <SheinDetail goodsId={id} />,
  };
  return id && store ? detailComponents[store] : null;
}

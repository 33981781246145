export const OPTIONS_REASON = {
  'Producto Vino Maltratado': 'Producto vino maltratado',
  'Amazon se Retraso': 'Amazon se retrasó',
  'El Producto se Adelanto': 'El producto se adelantó',
  'El Viajero se Retraso': 'El viajero se retrasó',
  'La Fecha de entrega de Amazon se Modifico':
    'La fecha de entrega de Amazon se modificó',
  Otro: 'Otro',
};

export const MAX_PRODUCTS_PER_PAGE = 8;

export const DEFAULT_CART = {
  id: null,
  ownerUid: null,
  items: [],
};

export const PRODUCT_SOURCES = ['amazon', 'shein'];

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Button, Label, FormGroup } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import logo from '../../../assets/img/logos/col.png';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { auth, db } from '../../../util/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { useAuth } from '../../../hooks/useAuth';
import PasswordInput from '../../../components/Inputs/PasswordInput/PasswordInput';

export function RegisterPage() {
  const [loading, setLoading] = React.useState(false);
  const { watch, setValue, handleSubmit } = useForm();
  const { checkout, setCheckout, profile, localUser, user } = useAuth();
  const navigate = useNavigate();
  const storeWatcher = watch();

  useEffect(() => {
    if (profile || localUser || user) {
      navigate(checkout ? '/checkout' : '/', { replace: true });
      setCheckout(false);
    }
  }, [profile, navigate]);

  const onSubmit = async (data) => {
    if (
      !data.firstName ||
      !data.lastName ||
      !data.phoneNumber ||
      !data.email ||
      !data.password
    ) {
      CustomToast('error', 'Asegurate de llenar todos los campos');
      return;
    }
    if (data.password.length < 6) {
      CustomToast('error', 'La contraseña debe tener al menos 6 caracteres');
      return;
    }
    if (!data.termsAccepted) {
      CustomToast('error', 'Debes aceptar los términos y condiciones');
      return;
    }
    try {
      setLoading(true);
      await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      ).then(async (res) => {
        localStorage.setItem('user', JSON.stringify(res.user));
        await addDoc(collection(db, 'Users'), {
          uid: res.user?.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phoneNumber,
          email: data.email,
          isAdmin: false,
          createdAt: Date.now(),
        }).then(() => {
          CustomToast('success', 'Cuenta creada exitosamente');
          navigate(checkout ? '/checkout' : '/', { replace: true });
          setCheckout(false);
        });
      });
    } catch {
      CustomToast(
        'error',
        'No se ha podido crear la cuenta, por favor intentalo mas tarde',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="loginPage">
      <Link to="/" className="back-arrow">
        <IoIosArrowRoundBack size={50} color="#fecf41" />
      </Link>
      <div className="loginContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <img
            alt="logo"
            src={logo}
            style={{
              width: 200,
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className="authLabel">Nombre</label>
            <Input
              type="text"
              className="mainInput"
              placeholder="John"
              onChange={(e) => setValue('firstName', e.target.value)}
            />

            <label className="authLabel">Apellido</label>
            <Input
              type="text"
              className="mainInput"
              placeholder="Doe"
              onChange={(e) => setValue('lastName', e.target.value)}
            />

            <label className="authLabel">Número de teléfono</label>
            <Input
              type="text"
              className="mainInput"
              placeholder="xxxx-xxxx"
              onChange={(e) => setValue('phoneNumber', e.target.value)}
            />

            <label className="authLabel">Correo electrónico</label>
            <Input
              type="email"
              className="mainInput"
              placeholder="johndoe@example.com"
              onChange={(e) => setValue('email', e.target.value)}
            />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="authLabel">Contraseña</label>
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'flex-start',
                }}
              >
                <PasswordInput
                  value={storeWatcher.password}
                  onChange={(e) => setValue('password', e.target.value)}
                />
              </div>
            </div>
          </div>
          <FormGroup check>
            <Input
              type="checkbox"
              onChange={(e) => setValue('termsAccepted', e.target.checked)}
            />
            <Label check>
              He leído y acepto los{' '}
              <Link className="terms-link" to="/terms" target="_blank">
                términos y condiciones
              </Link>
            </Label>
          </FormGroup>
          <Button type="submit" className="primaryBtn" disabled={loading}>
            Crear cuenta
          </Button>
          <p>
            ¿Ya tienes cuenta?{' '}
            <Link className="authLink" to="/login">
              Inicia sesión
            </Link>{' '}
            aquí.
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

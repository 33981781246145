import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Si estás usando React Router

export const NotFound = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <Row>
        <Col>
          <h1 className="text-center mb-4">404 - Página no encontrada</h1>
          <p className="text-center">
            Lo siento, la página que buscas no se pudo encontrar.
          </p>
          <div className="d-flex justify-content-center mt-4">
            <Button as={Link} to="/" variant="primary">
              Ir a Inicio
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

import React from 'react';
import { Button, Input } from 'reactstrap';

const DiscountCodeInput = ({
  infoDiscount,
  discountCode,
  setDiscountCode,
  loadingCode,
  verifyDiscountCode,
  clearDiscountCode,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    verifyDiscountCode(discountCode);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '0.25rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: '0.5rem',
            }}
          >
            <Input
              required
              disabled={infoDiscount}
              type="text"
              className="mainInput"
              placeholder="CODE"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <Button
              type="button"
              disabled={!discountCode || loadingCode}
              className="secondaryBtn"
              style={{ width: 'auto' }}
              onClick={clearDiscountCode}
            >
              Borrar
            </Button>
          </div>
          {!infoDiscount && (
            <Button
              type="submit"
              disabled={!discountCode || loadingCode}
              className="primaryBtn"
            >
              {loadingCode ? 'Aplicando...' : 'Aplicar'}
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export default DiscountCodeInput;

import React from 'react';
import { MatchRoles } from './MatchRoles.helper';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import PageLoader from '../components/Loaders/PageLoader/PageLoader';

export const ProtectedRoute = ({ roles }) => {
  const { loading, profile, localUser, user } = useAuth();

  // Redirect to login if no user is authenticated
  if (!loading && !profile && !localUser && !user)
    return <Navigate to="/login" replace />;

  // Check roles if provided
  if (!loading && roles && profile) {
    const passedRolesTest = MatchRoles(roles, profile.isAdmin);
    if (!passedRolesTest) return <Navigate to="/not-found" />;
  } else return <PageLoader />;

  // Render the nested routes if all conditions pass
  return <Outlet />;
};

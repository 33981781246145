import React from 'react';
import Select from 'react-select';

export default function CustomSelect({ value, setValue, options }) {
  return (
    <Select
      value={value}
      onChange={setValue}
      options={options}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: '#000000', // Dark background for the control
          borderColor: '#fdd835', // Yellow border color
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
          ...styles,
          color: isDisabled
            ? '#ccc'
            : isSelected || isFocused
              ? 'black'
              : 'white', // Black text when selected or hovered, otherwise white
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? '#ffd000' // Yellow background for selected option
              : isFocused
                ? '#fdd835' // Lighter yellow background for focused option
                : null,
          cursor: isDisabled ? 'not-allowed' : 'default',
        }),
        singleValue: (styles) => ({
          ...styles,
          color: 'white', // White text for selected value
        }),
        input: (styles) => ({
          ...styles,
          color: 'white', // White text for input text
        }),
        placeholder: (styles) => ({
          ...styles,
          color: 'white', // White text for placeholder
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          color: '#fdd835', // Yellow color for dropdown indicator
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          backgroundColor: '#fdd835', // Yellow color for the separator
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: '#000000', // Dark background for the dropdown menu
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#fdd835', // Light yellow background on option hover
          primary: '#ffd000', // Yellow for selected option and border
          neutral0: '#000000', // Dark background for the dropdown
          neutral80: '#ffffff', // White color for the text
          neutral20: '#fdd835', // Yellow color for the border
          neutral50: '#ffffff', // White color for placeholder text
        },
      })}
    />
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { CustomToast } from '../../../Notifications/CustomToast';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import FirebaseService from '../../../../services/firebase.service';
import {
  calculateWeight,
  getAsinFromProductDetails,
  getPriceFromProductDetails,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
  getWeigthItemDimesion,
} from '../../../../util/amazon';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import { calculateAmazonPrice } from '../../../../util/price';
import AmazonService from '../../../../services/amazon.service';
import ModalLoader from '../../../Loaders/ModalLoader/ModalLoader';

const AmazonAddModal = ({
  isOpen,
  toggle,
  productDefault,
  listTrips = [],
  listDrivers = [],
  productHistory = [],
  categoryWeights = [],
  variantOptions = [],
  setProductReportModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weightProduct, setWeigthProduct] = useState(null);

  const { profile, cart, setCart } = useAuth();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    if (product === null && productDefault) {
      setProduct(productDefault);
      if (variantOptions?.length > 0) {
        const defaultVariant = variantOptions.find(
          (item) => item.asin === productDefault.asin,
        );
        if (defaultVariant) {
          setVariant({
            value: defaultVariant.asin,
            label: defaultVariant.title,
          });
        }
      }
    }
  }, [product, productDefault, variantOptions]);

  useEffect(() => {
    const fetchData = async () => {
      if (!variant) return;
      try {
        setLoading(true);
        const response = await amazonService.detail(variant.value);
        setProduct(response?.product || null);
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [variant]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = getAsinFromProductDetails(product);
    const categoryList =
      product?.categories?.map((value) => value.category_id?.toLowerCase()) ||
      [];
    let price = getPriceFromProductDetails(product);
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth || '';
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && Number(price) > 500) ||
      (historyPrice && Number(historyPrice) > 500)
    )
      return null;
    if (historyPrice) price = Number(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = product?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(product),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(historyWeight);
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      setWeigthProduct(weigthAmazon);
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      setWeigthProduct(weightDimensionParsed);
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (weightDefault) {
      if (Number(weightDefault) > 15) return null;
      if (Number(weightDefault) < 0.25) weightDefault = 0.25;
      setWeigthProduct(weightDefault);
      return calculateAmazonPrice(
        price,
        Number(weightDefault) || 0,
        shippingAmazon || 0,
      );
    }
    return null;
  }, [product]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const price = product?.buybox_winner?.rrp?.value;
    if (!price || Number(price) > 500) return null;
    const asinProduct = getAsinFromProductDetails(product);
    const categoryList =
      product?.categories?.map((value) => value.category_id?.toLowerCase()) ||
      [];
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth || '';
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = product?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(product),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(historyWeight);
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      setWeigthProduct(weigthAmazon);
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      setWeigthProduct(weightDimensionParsed);
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (weightDefault) {
      if (Number(weightDefault) > 15) return null;
      if (Number(weightDefault) < 0.25) weightDefault = 0.25;
      setWeigthProduct(weightDefault);
      return calculateAmazonPrice(
        price,
        Number(weightDefault) || 0,
        shippingAmazon || 0,
      );
    }
    return null;
  }, [product]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon =
      product?.buybox_winner?.fulfillment?.standard_delivery?.date || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = listTrips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = listDrivers.find(
        (user) => user?.uid === info.travelerUid,
      );
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = listDrivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + Number(daysAfterTravel));
    return dateTravel;
  }, [product, listTrips, listDrivers]);

  const handleAddToCart = async () => {
    if (!product) return;
    try {
      setLoadingCart(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        weigthAmazon: weightProduct ?? null,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        variant,
        color: null,
        size: null,
        ttl: Number(process.env.REACT_APP_CART_TTL),
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile) await firebaseService.updateCart(profile, items);
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoadingCart(false);
    }
  };

  return (
    <Modal id="productModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: '1rem',
        }}
      >
        <IoClose onClick={toggle} />
      </div>
      {loading ? (
        <ModalLoader />
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <img
              src={product?.main_image?.link}
              alt="Foto"
              style={{ width: '200px', height: '200px', objectFit: 'contain' }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {product?.title}
              </p>
              {estimateDeliveryDate && (
                <p className="productDeliveryDate">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              {salePrice && (
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            {variantOptions && variantOptions?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">
                  Colores/Tallas/Capacidades/Tamaños:
                </p>
                <CustomSelect
                  value={variant}
                  setValue={setVariant}
                  options={variantOptions.map((item) => {
                    return {
                      value: item.asin,
                      label: item.title,
                    };
                  })}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
              gap: '1rem',
            }}
          >
            <Button className="secondaryBtn" onClick={toggle}>
              Cancelar
            </Button>
            <Button
              className="primaryBtn"
              onClick={
                salePrice ? handleAddToCart : () => setProductReportModal(true)
              }
            >
              {salePrice
                ? loadingCart
                  ? 'Agregando al carrito...'
                  : 'Agregar al carrito'
                : 'Solicitar cotizacion'}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AmazonAddModal;

import '../../assets/css/Header.css';
import React, { useEffect, useMemo } from 'react';
import {
  Link,
  useNavigate,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Input, Button } from 'reactstrap';
import { PiShoppingCartSimple } from 'react-icons/pi';
import { useForm } from 'react-hook-form';
import { SourceButtons } from '../../components/Buttons/SourceButtons';
import { identifyProductID } from '../../util/sources';

export function StoreLayout() {
  const { profile, cart } = useAuth();
  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      query: '',
      source: 'all',
    },
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const params = new URLSearchParams(location.search);
  const query = params.get('q');
  const source = params.get('s') || 'all';
  const storeWatcher = watch();

  const store = useMemo(
    () => (id ? identifyProductID(id) : source),
    [id, source],
  );

  useEffect(() => {
    if (query) setValue('query', query);
    if (store) setValue('source', store);
    if (!store && source) setValue('source', source);
  }, [location, setValue, store]);

  useEffect(() => {
    if (source !== storeWatcher?.source) setValue('query', '');
  }, [source, storeWatcher?.source, setValue]);

  const onSubmit = (data) => {
    const searchValue = data.query.trim().toLowerCase();
    const url = `/search?q=${encodeURIComponent(searchValue)}&s=${data.source}&p=1`;
    navigate(url);
  };

  const greeting = useMemo(() => {
    return !profile
      ? 'Bienvenido a Passeio!'
      : `Hola ${profile?.firstName} ${profile?.lastName}!`;
  }, [profile]);

  const cartItemCount = useMemo(() => {
    return cart?.items?.length || 0;
  }, [cart]);

  return (
    <div id="dashboardPage" className="page">
      <div className="header-container">
        <h2>{greeting}</h2>
        <Link to="/checkout" className="cart-link">
          <PiShoppingCartSimple className="cart-icon" />
          <span className="cart-count">{cartItemCount}</span>
        </Link>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="search-form">
            <div id="search-container">
              <Input
                required
                type="text"
                className="mainInput search-input"
                placeholder="Buscar cualquier producto o introduce un enlace"
                value={storeWatcher?.query}
                onChange={(e) => setValue('query', e.target.value)}
              />
              <Button type="submit" id="search-btn" className="primaryBtn">
                Buscar
              </Button>
            </div>
            <SourceButtons
              options={[
                { value: 'amazon', label: 'Amazon' },
                { value: 'shein', label: 'Shein' },
                { value: 'all', label: 'Todos' },
              ]}
              value={storeWatcher?.source}
              setValue={setValue}
            />
            <h3 className="text-center store-label">
              Selecciona una tienda para buscar
            </h3>
          </div>
        </form>
      </div>
      <Outlet />
    </div>
  );
}

import React from 'react';

const TermSection = ({ title, content }) => {
  return (
    <div>
      <h2>{title}</h2>
      {content.split('\n\n').map((str, index) => (
        <p key={index}>{str}</p>
      ))}
    </div>
  );
};

export default TermSection;

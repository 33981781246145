import './style.css';
import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../assets/img/logos/row.png';

const Header = React.memo(({ toggleSidebar, user }) => {
  return (
    <header>
      <div>
        <Link to="/">
          <img alt="logo" src={logo} id="logo" />
        </Link>
      </div>
      <div id="navbarItems">
        <div>
          <Link className="navbarItem" to="/">
            Passeio
          </Link>
          {user && (
            <>
              <Link className="navbarItem" to="/orders">
                Pedidos
              </Link>
              {user?.isAdmin && (
                <Link className="navbarItem" to="/admin">
                  Admin
                </Link>
              )}
              <Link className="navbarItem" to="/account">
                Cuenta
              </Link>
            </>
          )}
        </div>
        {!user && (
          <Link to="/login">
            <Button className="primaryBtn">Iniciar sesion</Button>
          </Link>
        )}
      </div>
      <FiMenu id="hamburger" onClick={toggleSidebar} />
    </header>
  );
});

export default Header;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Input } from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import { Calendar } from 'react-big-calendar';
import classnames from 'classnames';
import { IoClose } from 'react-icons/io5';
import ReactPaginate from 'react-paginate';

export default function AdminTravelersPage({
  activeDriversTab,
  setActiveDriversTab,
  scheduleTravelerModalIsVisible,
  toggleScheduleTravelerModal,
  dateToSchedule,
  setDateToSchedule,
  travelerToSchedule,
  setTravelerToSchedule,
  scheduleTraveler,
  localizer,
  events,
  toggleCalendarEventModal,
  calendarEventModalIsVisible,
  activeCalendarEvent,
  drivers,
  deleteCalendarEvent,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = drivers.filter(
        (driver) =>
          driver.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          driver.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          driver.email.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredDrivers(filtered);
    } else {
      setFilteredDrivers(drivers);
    }
  }, [searchQuery, drivers]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDrivers.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <div className="tabBar">
        <Button
          onClick={() => setActiveDriversTab(1)}
          className={classnames('subTabButton', {
            active: activeDriversTab === 1,
          })}
        >
          Calendario
        </Button>
        <Button
          onClick={() => setActiveDriversTab(2)}
          className={classnames('subTabButton', {
            active: activeDriversTab === 2,
          })}
        >
          Lista
        </Button>
      </div>
      {activeDriversTab === 1 ? (
        <div>
          <Button
            onClick={() => toggleScheduleTravelerModal(null)}
            className="primaryBtn"
            style={{ marginBottom: '1rem' }}
          >
            Agendar viajero
          </Button>
          <Modal
            isOpen={scheduleTravelerModalIsVisible}
            toggle={toggleScheduleTravelerModal}
            size="lg"
            centered
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                }}
              >
                <IoClose onClick={() => toggleScheduleTravelerModal(null)} />
              </div>
              <div>
                <p className="cardHeader">Agendar viajero</p>
                <p className="label">Fecha</p>
                <DatePicker
                  id="date-to-schedule-datepicker"
                  className="mainInput"
                  style={{ borderRadius: 10 }}
                  showClearButton={false}
                  value={dateToSchedule}
                  onChange={(v) => setDateToSchedule(v)}
                  dateFormat="DD.MM.YYYY"
                  placeholder="La fecha a agendar"
                />
                <p className="label">Viajero</p>
                <Input
                  type="select"
                  className="mainInput"
                  value={travelerToSchedule}
                  onChange={(e) => setTravelerToSchedule(e.target.value)}
                  defaultValue={''}
                >
                  <option value={''} disabled>
                    El viajero a agendar
                  </option>
                  {drivers?.map((item, index) => {
                    return (
                      <option key={index} value={item.uid}>
                        {item.firstName + ' ' + item.lastName}
                      </option>
                    );
                  })}
                </Input>
              </div>
              <Button
                onClick={scheduleTraveler}
                className="primaryBtn"
                style={{ marginTop: '0.5rem' }}
                disabled={!dateToSchedule || !travelerToSchedule}
              >
                Agendar
              </Button>
            </div>
          </Modal>
          <Calendar
            localizer={localizer}
            events={events}
            defaultDate={new Date()}
            defaultView="month"
            views={['month', 'agenda']}
            messages={{
              next: 'Sig.',
              previous: 'Ant.',
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              date: 'Fecha',
              time: 'Hora',
              event: 'Evento',
              noEventsInRange: 'No hay eventos en este rango',
            }}
            style={{ height: 500 }}
            onSelectEvent={toggleCalendarEventModal}
          />
          <Modal
            isOpen={calendarEventModalIsVisible}
            toggle={toggleCalendarEventModal}
            size="lg"
            centered
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                }}
              >
                <IoClose onClick={toggleCalendarEventModal} />
              </div>
              <div>
                <p className="cardHeader">
                  Viajeros para el{' '}
                  {new Date(activeCalendarEvent?.start).toLocaleDateString(
                    'es-ES',
                    {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}
                </p>
                <Button
                  onClick={() =>
                    toggleScheduleTravelerModal(activeCalendarEvent?.start)
                  }
                  className="primaryBtn"
                  style={{ marginBottom: '1rem' }}
                >
                  Agregar viajero
                </Button>
                {events?.map((item, index) => {
                  if (item?.start === activeCalendarEvent?.start) {
                    return (
                      <div key={index} className="calendarEventModalTraveler">
                        <p>{item.title}</p>
                        <IoClose onClick={() => deleteCalendarEvent(item)} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="table-container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '0.25rem',
            }}
          >
            <Input
              type="text"
              className="mainInput"
              placeholder="Buscar..."
              style={{ marginBottom: 0 }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              onClick={() => navigate('/driver/create')}
              className="primaryBtn"
              style={{
                width: 'min-content',
                paddingLeft: '3rem',
                paddingRight: '3rem',
                marginLeft: '1rem',
              }}
            >
              Crear
            </Button>
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index} onClick={() => navigate(`/driver/${item.uid}`)}>
                  <td data-title="ID">{item.customer_id || 'N/A'}</td>
                  <td data-title="Nombre">
                    {item.firstName + ' ' + item.lastName}
                  </td>
                  <td data-title="Correo">{item.email}</td>
                  <td data-title="Estado">
                    {item.driverStatus === 'active' ? 'Activo' : 'Inactivo'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Siguiente'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(filteredDrivers.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            previousClassName={'previous'}
            nextClassName={'next'}
            disabledClassName={'disabled'}
          />
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import './TextLoader.css';

export default function TextLoader({ texts, style, interval = 3000 }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const textChangeInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);
    return () => clearInterval(textChangeInterval);
  }, [texts, interval]);

  const loaderStyle = {
    ...style,
    '--loader-text': `"${texts[currentIndex]}"`,
    '--animation-duration': `${interval}ms`,
  };

  return <div className="loader" style={loaderStyle} />;
}

import React from 'react';
import CardOption from '../CardOption/CardOption';
import { CustomToast } from '../../Notifications/CustomToast';

const CardOptionSelector = ({
  options,
  activeOption,
  setActiveOption,
  sessionKey,
  disabledOptionCheck,
  toastMessage,
}) => {
  const handleOptionClick = (id) => {
    if (disabledOptionCheck && disabledOptionCheck(id)) {
      CustomToast('warning', toastMessage);
      return;
    }
    setActiveOption(id);
    sessionStorage.setItem(sessionKey, JSON.stringify(id));
  };

  return (
    <div id="cartOptionContainer">
      {options.map((option) => (
        <CardOption
          key={option.id}
          id={option.id}
          isActive={activeOption === option.id}
          onClick={handleOptionClick}
          title={option.title}
          description={option.description}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

export default CardOptionSelector;

import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Footer from '../../components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { ToastContainer } from 'react-toastify';

export const MainLayout = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const { profile } = useAuth();

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  return (
    <>
      {sidebarIsOpen && (
        <Sidebar toggleSidebar={toggleSidebar} user={profile} />
      )}
      <Header toggleSidebar={toggleSidebar} user={profile} />
      <Outlet />
      <ToastContainer />
      <Footer />
    </>
  );
};

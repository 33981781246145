import {
  query,
  getDocs,
  collection,
  orderBy,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { cleanPhone } from '../../../util/number';
import { formatDateISO } from '../../../util/date';
import styles from './AdminRequestPage.module.css';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import ReactPaginate from 'react-paginate';
import { Button, Input } from 'reactstrap';

const OPTIONS_TAB = {
  all: 'all',
  check: 'check',
};

export const AdminRequestPage = ({ users = [] }) => {
  const [listRequest, setListRequest] = useState([]);
  const [tabSelect, setTabSelect] = useState(OPTIONS_TAB.all);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const listRequestShow = useMemo(() => {
    const filteredList = listRequest.filter((value) => {
      const fullName =
        `${value.user?.firstName} ${value.user?.lastName}`.toLowerCase();
      const phoneNumber = value.user?.phone || 'N/A';
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        phoneNumber.includes(searchQuery)
      );
    });

    if (tabSelect === OPTIONS_TAB.all) {
      return filteredList.filter((value) => !value.isCheck);
    }
    return filteredList.filter((value) => value.isCheck);
  }, [listRequest, tabSelect, searchQuery]);

  const getRequest = async () => {
    const productsRef = collection(db, 'requestProduct');
    const q = query(productsRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const list = [];
    querySnapshot.forEach((doc) => {
      const newProduct = doc.data();
      const findUser = users.find((value) => value.uid === newProduct?.uidUser);
      list.push({
        ...newProduct,
        uidDoc: doc.id,
        user: findUser || null,
      });
    });
    setListRequest(list);
  };

  const handleCheckRequest = async (request) => {
    const refRequest = doc(db, 'requestProduct', request.uidDoc);

    await updateDoc(refRequest, { isCheck: true }).then(() => {
      CustomToast('success', 'Solicitud marcada como revisada');
      getRequest();
    });
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    getRequest();
  }, []);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listRequestShow.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-container">
      <div
        className={styles.contentTab}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '0.25rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          className={`primaryBtn ${tabSelect === OPTIONS_TAB.all ? styles.tabActive : undefined}`}
          onClick={() => setTabSelect(OPTIONS_TAB.all)}
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
            whiteSpace: 'nowrap',
          }}
        >
          Sin Revisar
        </Button>
        <Button
          className={`primaryBtn ${tabSelect === OPTIONS_TAB.check ? styles.tabActive : undefined}`}
          onClick={() => setTabSelect(OPTIONS_TAB.check)}
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
            whiteSpace: 'nowrap',
          }}
        >
          Revisadas
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Teléfono</th>
            <th>Enlace</th>
            <th>Fecha</th>
            <th>Contacto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => {
            return (
              <tr key={index}>
                <td data-title="Nombre">
                  {item.user?.firstName || item.firstName}{' '}
                  {item.user?.lastName || item.lastName}
                </td>
                <td data-title="Telefono">
                  {item.user?.phone || item.phoneNumber || 'N/A'}
                </td>
                <td data-title="Enlace">
                  <Link
                    to={item.url}
                    target="_blank"
                    style={{ color: '#FFD000' }}
                  >
                    Enlace
                  </Link>
                </td>
                <td data-title="Fecha">
                  {formatDateISO(new Date(item.createdAt))}
                </td>
                <td data-title="Contacto">
                  <Link
                    to={`https://api.whatsapp.com/send?phone=${cleanPhone(item.user?.phone || '')}`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: '#25D366',
                        borderRadius: 10,
                        border: 'none',
                        color: 'black',
                        fontWeight: '600',
                        width: '100%',
                      }}
                    >
                      WhatsApp
                    </button>
                  </Link>
                </td>
                <td data-title="Acciones">
                  {!item.isCheck && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleCheckRequest(item)}
                      style={{
                        backgroundColor: '#FFD000',
                        borderRadius: 10,
                        border: 'none',
                        color: 'black',
                        fontWeight: '600',
                        width: '100%',
                      }}
                    >
                      Marcar como revisado
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(listRequestShow.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
};

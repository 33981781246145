import React from 'react';

const BankAccountAddress = ({
  bankName,
  accountType,
  accountNumber,
  accountName,
  email,
}) => {
  return (
    <div className="bankAccountAddress">
      <p style={{ fontWeight: 'bold' }}>{bankName}</p>
      <p>Tipo de cuenta: {accountType}</p>
      <p>Número de cuenta: {accountNumber}</p>
      <p>Nombre: {accountName}</p>
      <p>Correo: {email}</p>
    </div>
  );
};

export default BankAccountAddress;

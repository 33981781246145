import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { Input, Button } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export function AdminEditProductPage() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      nameProduct: '',
      urlPhoto: '',
      weigthEstimate: 0,
      currentWeigth: 0,
      price: null,
      asin: '',
      urlProduct: '',
      category: '',
      comment: '',
      status: true,
    },
  });
  const navigate = useNavigate();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!id) return;
      try {
        setLoading(true);
        await firebaseService.findOneHistoryProduct(id).then((response) => {
          setValue('nameProduct', response?.nameProduct);
          setValue('urlPhoto', response?.urlPhoto);
          setValue('weigthEstimate', response?.weigthEstimate);
          setValue('currentWeigth', response?.currentWeigth);
          setValue('price', response?.price);
          setValue('asin', response?.asin);
          setValue('urlProduct', response?.urlProduct);
          setValue('category', response?.category);
          setValue('status', response?.status);
        });
      } catch {
        CustomToast('error', 'No se pudo cargar la información del producto');
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await firebaseService.updateHistoryProduct(data.asin, data);
      navigate(-1);
    } catch {
      CustomToast('error', 'No se pudo guardar los cambios');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Editar Producto
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">ASIN</p>
        <Input
          required
          type="text"
          className="mainInput"
          defaultValue={getValues('asin')}
          onChange={(e) => setValue('asin', e.target.value)}
          placeholder="B092QGSJ36"
          disabled
        />
        <p className="label">Nombre</p>
        <Input
          required
          type="text"
          className="mainInput"
          defaultValue={getValues('nameProduct')}
          onChange={(e) => setValue('nameProduct', e.target.value)}
          placeholder="Computer"
        />
        <p className="label">Peso Estimado</p>
        <Input
          required
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('weigthEstimate')}
          onChange={(e) => setValue('weigthEstimate', e.target.value)}
          placeholder="0"
        />
        <p className="label">Peso Real</p>
        <Input
          required
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('currentWeigth')}
          onChange={(e) => setValue('currentWeigth', e.target.value)}
          placeholder="0"
        />
        <p className="label">Precio</p>
        <Input
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('price')}
          onChange={(e) => setValue('price', e.target.value)}
          placeholder="0"
        />
        <p className="label">Comentarios</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('comment')}
          onChange={(e) => setValue('comment', e.target.value)}
          placeholder="Good product!"
        />
        <p className="label">Link</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('urlProduct')}
          onChange={(e) => setValue('urlProduct', e.target.value)}
          placeholder="https://amazon.com"
        />
        <p className="label">Categoria</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('category')}
          onChange={(e) => setValue('category', e.target.value)}
          placeholder="Electronics"
        />

        {getValues('urlPhoto') && (
          <>
            <p className="label">Imagen</p>
            <img
              src={getValues('urlPhoto')}
              alt="product"
              width={250}
              height={200}
              style={{ objectFit: 'cover', marginTop: '10px' }}
            />
          </>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input text-white"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked={getValues('status')}
              onChange={(e) => setValue('status', e.target.checked)}
            />
            <label
              className="form-check-label text-white"
              htmlFor="flexCheckChecked"
            >
              Producto activo
            </label>
          </div>
        </div>

        <Button
          type="submit"
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
        >
          Guardar
        </Button>
      </form>
    </div>
  );
}

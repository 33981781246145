import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';

export default function AdminProductsPage({ products }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const sorted = products.sort((a, b) => {
      if (a.status && !b.status) {
        return -1;
      }
      if (!a.status && b.status) {
        return 1;
      }
      return 0;
    });
    setFilteredProducts(sorted);
  }, [products]);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = products.filter(
        (product) =>
          product.nameProduct
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          product.asin.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [searchQuery, products]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          onClick={() => navigate('/product/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Foto</th>
            <th>ASIN</th>
            <th>Nombre</th>
            <th>Peso</th>
            <th>Precio</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, index) => (
            <tr key={index} onClick={() => navigate(`/product/${item?.uid}`)}>
              <td data-title="Foto">
                <div className="productsListItemAvatar">
                  <img
                    src={
                      item?.urlPhoto ||
                      'https://placehold.co/600x400?text=Not+Found'
                    }
                    alt="Avatar"
                    className="avatar"
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </td>
              <td data-title="ASIN">
                {item?.asin?.length > 10
                  ? item?.asin?.substring(0, 10) + '...'
                  : item?.asin}
              </td>
              <td data-title="Nombre">{item?.nameProduct}</td>
              <td data-title="Peso">{item?.currentWeigth || 'N/A'}</td>
              <td data-title="Precio">{item?.price || 'N/A'}</td>
              <td data-title="Estado">{item.status ? 'Activo' : 'Inactivo'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
}

import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiTrashSimpleBold } from 'react-icons/pi';

const DeliveryAddresses = ({
  deliveryAddresses,
  otherDeliveryAddressesAreVisible,
  toggleOtherDeliveryAddresses,
  changeDeliveryAddress,
  deleteDeliveryAddress,
}) => {
  return deliveryAddresses?.length > 0 ? (
    <div style={{ marginBottom: '1rem' }}>
      {deliveryAddresses.map((item, index) => {
        if (item.isActive) {
          return (
            <div key={index} className="checkoutDeliveryAddress active">
              <p>{item.streetAddress}</p>
              <p>
                {item.city}, {item.country}
              </p>
            </div>
          );
        }
        return null;
      })}
      {!otherDeliveryAddressesAreVisible && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '0.5rem',
          }}
        >
          <FaChevronDown
            onClick={toggleOtherDeliveryAddresses}
            style={{
              color: '#FFD000',
              width: 25,
              height: 25,
              cursor: 'pointer',
            }}
          />
        </div>
      )}
      {otherDeliveryAddressesAreVisible && (
        <>
          {deliveryAddresses.map((address, index) => {
            if (!address.isActive) {
              return (
                <div
                  key={index}
                  onClick={() => changeDeliveryAddress(address)}
                  className="checkoutDeliveryAddress"
                  style={{
                    marginTop: '0.5rem',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <p>{address.streetAddress}</p>
                    <p>
                      {address.city}, {address.country}
                    </p>
                  </div>
                  <PiTrashSimpleBold
                    onClick={(e) => deleteDeliveryAddress(address, e)}
                    className="removeCartItemIcon"
                    style={{ marginTop: 0 }}
                  />
                </div>
              );
            }
            return null;
          })}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '0.5rem',
            }}
          >
            <FaChevronUp
              onClick={toggleOtherDeliveryAddresses}
              style={{
                color: '#FFD000',
                width: 25,
                height: 25,
                cursor: 'pointer',
              }}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <div style={{ marginBottom: '1rem' }}>
      <p style={{ textAlign: 'center' }}>Aún no tienes direcciones de envío</p>
    </div>
  );
};

export default DeliveryAddresses;

import axiosInstance from '../util/axios-req';

export default class AmazonService {
  static instance;

  static getInstance() {
    AmazonService.instance ??= new AmazonService();
    return AmazonService.instance;
  }

  async search(search_term, page = 1) {
    const response = await axiosInstance.get(`/amazon/request`, {
      params: {
        amazon_domain: 'amazon.com',
        type: 'search',
        search_term,
        customer_zipcode: 20110,
        is_prime: true,
        language: 'en_US',
        page,
      },
    });
    return {
      searchResults: response.data.search_results || [],
      numberOfResultsPages: response.data.pagination?.total_pages,
      currentResultsPage: response.data.pagination?.current_page,
    };
  }

  async detail(asin) {
    const response = await axiosInstance.get(`/amazon/request`, {
      params: {
        amazon_domain: 'amazon.com',
        type: 'product',
        customer_zipcode: 20110,
        is_prime: true,
        language: 'en_US',
        asin,
      },
    });
    return response.data;
  }

  async url(url) {
    const response = await axiosInstance.get(`/amazon/request`, {
      params: {
        type: 'product',
        customer_zipcode: 20110,
        is_prime: true,
        language: 'en_US',
        url,
      },
    });
    return response.data;
  }

  async deals(page = 1) {
    const response = await axiosInstance.get(`/amazon/request`, {
      params: {
        amazon_domain: 'amazon.com',
        type: 'deals',
        deal_states: 'available',
        sort_by: 'featured',
        page,
      },
    });
    return response.data;
  }
}

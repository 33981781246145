import React, { useEffect, useMemo, useState } from 'react';
import PageLoader from '../../../Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../Notifications/CustomToast';
import '../../../../assets/css/ProductDetail.css';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { calculateAmazonPrice } from '../../../../util/price';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import {
  calculateWeight,
  getAsinFromProductDetails,
  getPriceFromProductDetails,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
  getWeigthItemDimesion,
} from '../../../../util/amazon';
import { cancelAllRequests } from '../../../../util/axios-req';
import ImageViewer from '../../../Images/ImageViewer';
import { Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import StarRating from '../../../Reviews/StarRating/StarRating';
import { AmazonProductCard } from '../../Card/Amazon/AmazonProductCard';
import AmazonService from '../../../../services/amazon.service';
import FirebaseService from '../../../../services/firebase.service';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import { limitWords } from '../../../../util/string';

export default function AmazonDetail({ asin }) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [variant, setVariant] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weigthProduct, setWeigthProduct] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [categoryWeights, setCategoryWeights] = useState([]);
  const [productReportModal, setProductReportModal] = useState(false);

  const { profile, cart, setCart } = useAuth();
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !asin || product?.asin === asin) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchProduct();
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistoryPromise = searchProductHistory();
        const categoryWeightsPromise = searchCategoryWeights();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistoryPromise,
          categoryWeightsPromise,
        ]);
      } catch {
        CustomToast('error', 'Error al buscar productos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [asin]);

  const variantOptions = useMemo(() => {
    if (!product) return null;
    return product?.variants;
  }, [product]);

  useEffect(() => {
    if (loading || !product) return;
    console.debug(product);
    if (variantOptions && variantOptions?.length > 0) {
      setVariant({
        value: variantOptions?.find((item) => item.asin === product.asin).asin,
        label: variantOptions?.find((item) => item.asin === product.asin).title,
      });
    }
  }, [loading, product]);

  useEffect(() => {
    if (!variant?.value) return;
    navigate(`/detail/${variant.value}`);
  }, [variant]);

  useEffect(() => {
    if (!loading || !product || recommendations.length > 0) return;
    searchRecomendations();
  }, [loading, product]);

  const resetValues = async () => {
    cancelAllRequests();
    setProduct(null);
    setRecommendations([]);
    setVariant(null);
  };

  const searchProduct = async () => {
    try {
      const response = await amazonService.detail(asin);
      setProduct(response.product ?? null);
    } catch {
      navigate(`/404/product/${asin}`, { replace: true });
    }
  };

  const searchRecomendations = async () => {
    if (!product?.keywords && !product?.title) return;
    try {
      const searchTerm = product?.keywords
        ? limitWords(product.keywords, 5)
        : product.title
          ? limitWords(product.title, 5)
          : '';
      const response = await amazonService.search(searchTerm);
      if (response.searchResults.length > 0)
        setRecommendations(response.searchResults.slice(0, 10));
    } catch {
      CustomToast('error', 'Error al buscar recomendaciones');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchCategoryWeights = async () => {
    try {
      const response = await firebaseService.findActiveCategoryWeights();
      if (response.length > 0) setCategoryWeights(response);
    } catch {
      CustomToast('error', 'Error al buscar pesos de categorías');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const handleAddToCart = async () => {
    if (!product) return;
    if (variantOptions && variantOptions?.length > 0 && !variant) {
      CustomToast(
        'warning',
        'Por favor selecciona un color/talla/capacidad/tamaño',
      );
      return;
    }
    try {
      setLoading(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        weigthAmazon: weigthProduct,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        variant,
        color: null,
        size: null,
        ttl: Number(process.env.REACT_APP_CART_TTL),
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile) await firebaseService.updateCart(profile, items);
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    handleAddToCart();
    navigate('/checkout');
  };

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = getAsinFromProductDetails(product);
    const categoryList =
      product?.categories?.map((value) => value.category_id?.toLowerCase()) ||
      [];
    let price = getPriceFromProductDetails(product);
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && Number(price) > 500) ||
      (historyPrice && Number(historyPrice) > 500)
    )
      return null;
    if (historyPrice) price = Number(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = product?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(product),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let categoryWeight = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      console.debug('historyWeight', historyWeight);
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(historyWeight);
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
        true,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      console.debug('weightAmazonParsed', weightAmazonParsed);
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      setWeigthProduct(weigthAmazon);
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
        true,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      console.debug('weightDimensionParsed', weightDimensionParsed);
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      setWeigthProduct(weightDimensionParsed);
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
        true,
      );
    }
    if (categoryWeight) {
      console.debug('categoryWeight', categoryWeight);
      if (Number(categoryWeight) > 15) return null;
      if (Number(categoryWeight) < 0.25) categoryWeight = 0.25;
      setWeigthProduct(categoryWeight);
      return calculateAmazonPrice(
        price,
        Number(categoryWeight) || 0,
        shippingAmazon || 0,
        true,
      );
    }
    return null;
  }, [product, drivers, trips, productHistory, categoryWeights]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const price = product?.buybox_winner?.rrp?.value;
    if (!price || Number(price) > 500) return null;
    const asinProduct = getAsinFromProductDetails(product);
    const categoryList =
      product?.categories?.map((value) => value.category_id?.toLowerCase()) ||
      [];
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth || '';
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    let weightAmazonParsed = calculateWeight(weigthAmazon);
    const shippingAmazon = product?.buybox_winner?.shipping?.value || 0;
    // Dimension details
    let weigthDimension = getWeigthItemDimesion(
      getWeigthFromProductDimensions(product),
    );
    let weightDimensionParsed = calculateWeight(weigthDimension);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (Number(historyWeight) > 15) return null;
      if (Number(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(historyWeight);
      return calculateAmazonPrice(
        price,
        Number(historyWeight) || 0,
        shippingAmazon || 0,
      );
    }
    if (weigthAmazon && weightAmazonParsed) {
      if (Number(weightAmazonParsed) > 15) return null;
      if (Number(weightAmazonParsed) < 0.25) weightAmazonParsed = 0.25;
      setWeigthProduct(weigthAmazon);
      return calculateAmazonPrice(
        price,
        Number(weightAmazonParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (!weigthAmazon && weightDimensionParsed) {
      if (Number(weightDimensionParsed) > 15) return null;
      if (Number(weightDimensionParsed) < 0.25) weightDimensionParsed = 0.25;
      setWeigthProduct(weightDimensionParsed);
      return calculateAmazonPrice(
        price,
        Number(weightDimensionParsed) || 0,
        shippingAmazon || 0,
      );
    }
    if (weightDefault) {
      if (Number(weightDefault) > 15) return null;
      if (Number(weightDefault) < 0.25) weightDefault = 0.25;
      setWeigthProduct(weightDefault);
      return calculateAmazonPrice(
        price,
        Number(weightDefault) || 0,
        shippingAmazon || 0,
      );
    }
    return null;
  }, [product, drivers, trips, productHistory, categoryWeights]);

  const discountPercentage = useMemo(() => {
    if (!salePrice || !originalPrice) return null;
    return ((1 - salePrice / originalPrice) * 100).toFixed(2);
  }, [salePrice, originalPrice]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon =
      product?.buybox_winner?.fulfillment?.standard_delivery?.date || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = trips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = drivers.find((user) => user?.uid === info.travelerUid);
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = drivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + Number(daysAfterTravel));
    return dateTravel;
  }, [product, drivers, trips]);

  if (loading || !product) return <PageLoader />;

  return (
    <div className="detail-container">
      <div className="top-section">
        {product?.images?.length > 0 && (
          <div className="image-gallery">
            <ImageViewer
              images={product?.images.map((item) => {
                return {
                  src: item?.link,
                  description: item?.asin,
                };
              })}
            />
          </div>
        )}

        <div className="product-details">
          <h1 className="product-title">{product?.title}</h1>
          {product?.rating && (
            <div className="section-container">
              <StarRating rating={product.rating} />
            </div>
          )}
          <div className="price-container">
            <div className="price-section">
              {discountPercentage > 0 && (
                <span className="discount-percentage">
                  -{discountPercentage}%
                </span>
              )}
              {salePrice && (
                <span className="discount-price">${salePrice}</span>
              )}
            </div>
            {originalPrice !== salePrice && (
              <div className="price-section">
                <span className="original-price-title">Precio original: </span>
                <span className="original-price">${originalPrice}</span>
              </div>
            )}
          </div>

          {variantOptions && variantOptions?.length > 0 && (
            <div className="section-container">
              <p className="section-title">
                Colores/Tallas/Capacidades/Tamaños:
              </p>
              <CustomSelect
                value={variant}
                setValue={setVariant}
                options={variantOptions.map((item) => {
                  return {
                    value: item.asin,
                    label: item.title,
                  };
                })}
              />
            </div>
          )}

          {product?.description && (
            <div className="section-container">
              <p className="section-title">Sobre este producto:</p>
              <p>{product?.description}</p>
            </div>
          )}
        </div>

        <div className="purchase-options">
          {salePrice ? (
            <>
              <p className="section-title">Comprar nuevo:</p>
              <h2>${salePrice}</h2>
              <p>En existencias</p>
              {estimateDeliveryDate && (
                <p className="delivery-date">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              <div className="purchase-buttons">
                <Button className="primaryBtn" onClick={handleAddToCart}>
                  Agregar a carrito
                </Button>
                <Button className="secondaryBtn" onClick={handlePurchase}>
                  Comprar ahora
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="section-title">Este producto no esta disponible</p>
              <Button
                className="primaryBtn"
                onClick={() => setProductReportModal(true)}
              >
                Solicitar cotizacion
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="bottom-section">
        {product?.specifications?.length > 0 && (
          <div className="product-specifications">
            <p className="section-title">Detalles del producto:</p>
            {product?.specifications?.map((item, index) => (
              <div key={index} className="product-specifications-item">
                <p>{item.name}</p>
                <p>{item.value}</p>
              </div>
            ))}
          </div>
        )}

        {recommendations?.length > 0 && (
          <div className="product-recommendations">
            <p className="section-title">Recomendaciones:</p>
            <div className="recommendations-carousel">
              {recommendations.map((item, index) => (
                <div key={index} className="recommendation-item">
                  <AmazonProductCard
                    key={index}
                    product={item}
                    listTrips={trips}
                    listDrivers={drivers}
                    productHistory={productHistory}
                    categoryWeights={categoryWeights}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {product?.top_reviews?.length > 0 && (
          <div className="product-reviews">
            <p className="section-title">Reseñas principales</p>
            {product?.top_reviews?.map((item, index) => (
              <div key={index} className="product-review-item">
                <div className="product-review-header">
                  <img
                    src={
                      item.profile.image ??
                      `https://eu.ui-avatars.com/api/?name=${item.profile.name}&size=250`
                    }
                    alt={`${item.profile.name}'s profile`}
                    className="profile-image"
                  />
                  <Link to={item.profile.link} rel="noreferrer">
                    {item.profile.name}
                  </Link>
                </div>
                <div className="product-review-rating">
                  <StarRating rating={item.rating} />
                  <p style={{ fontWeight: 'bold' }}>{item.title}</p>
                </div>
                <p className="product-review-date">
                  {new Date(item.date.utc).toLocaleDateString('de-DE')}
                  {item.verified_purchase && (
                    <span>&nbsp;| Compra verificada</span>
                  )}
                </p>
                <p className="product-review-body">{item.body}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        infoProduct={{
          url: product?.link || '',
          title: product?.title || '',
          image: product?.main_image?.link || '',
        }}
      />
    </div>
  );
}

import React from 'react';
import './TermsPage.css';
import TermSection from '../../../components/Terms/TermSection';

export function TermsPage() {
  const sections = [
    {
      title: '1. General',
      content: `
Mediante su acceso o uso de los Servicios de la Plataforma PASSEIO, usted (el "Usuario") acuerda
obligarse al cumplimiento de los presentes Términos y Condiciones, los cuales regulan la relación,
derechos y obligaciones entre el Usuario y PASSEIO, S.A. DE C.V. ("PASSEIO", "Nos”, "Nuestros",
"Nosotros") en cuanto a los servicios ofrecidos por la plataforma PASSEIO. Si el Usuario no acepta estos
Términos y Condiciones, no podrá acceder o usar los Servicios proporcionados por la plataforma
PASSEIO. 
\n\nPasseio se compromete a que recibas tu producto o en el casos extraordinarios te regresamos tu dinero.
Hay ciertos casos en los que puedes regresar tus productos, para mayor detalle por favor contactanos 
por whatsapp +503 7950 2151.
\n\nEn caso de no haber hecho la transferencia después de 24 horas de hacer el pedido, el pedido se va a 
cancelar.
\n\nEn caso de que se entregue el producto incorrecto, sin preguntas se va a regresar y entregamos el 
correcto.
\n\nEl método de pago que usamos para tarjetas es a través de Wompi, una herramienta totalmente 
confiable y segura.
\n\nEl proceso para realizar una compra es el siguiente.
\n\nSi quieres ser viajero, favor de comunicarte con Passeio a través de whatsapp.
\n\nPASSEIO se reserva el derecho de poner fin de manera inmediata a estos Términos y Condiciones o
cualquiera de los Servicios proporcionados al Usuario o, en general, dejar de ofrecer o denegar el acceso o
uso de los Servicios de manera total o parcial, en cualquier momento y cuando exista un incumplimiento a
los Términos y Condiciones, establecidas por medio de un aviso simple a los Usuarios.
\n\nPASSEIO tiene la facultad de modificar los presentes Términos y Condiciones relativos a los Servicios
brindados cuando lo considere conveniente, lo cual lo hará del conocimiento de los Usuarios mediante un
aviso mediante la aplicación, correo electrónico o mensaje de texto. Las modificaciones serán efectivas
después de la comunicación por parte de PASSEIO bajo cualquier modalidad de que los Términos y
Condiciones han sido actualizados sobre los Servicios aplicables. Una vez comunicado los nuevos
Términos y Condiciones, cuando el Usuario haga acceso o uso de los Servicios después de dicha
comunicación de las modificaciones, se entenderá como un consentimiento tácito y vinculante hacia los
presentes Términos y Condiciones y sus modificaciones correspondientes.
\n\nLa recopilación y el uso que hacemos de su información personal en relación con los Servicios será tratada
conforme a las Políticas de Privacidad de PASSEIO y de conformidad a las leyes vigentes de aplicación,
relativas al manejo de datos personales, las cuales se pueden consultar en la aplicación. PASSEIO tendrá
la facultad de proporcionar la información personal del Usuario, sin consentimiento de éste, siempre que
esta sea requerida mediante resolución administrativa o judicial emitida por las autoridades competentes.
            `,
    },
    {
      title: '2. Los Servicios de PASSEIO y Responsabilidades de los Usuarios.',
      content: `
Los servicios a brindarse por PASSEIO consistirán en todos aquellos detallados en la presente Cláusula 2
en su totalidad, considerando la aplicación de todos los derechos, obligaciones y limitaciones establecidas
en la misma (en los presentes términos y condiciones, en su totalidad referidos como los "Servicios", según
se describan en la presente cláusula). Los Servicios son proporcionados a través de una plataforma digital
que incluye el acceso a la aplicación web de PASSEIO para la prestación de los Servicios. Para acceder a
los Servicios referidos en la presente Cláusula, el Usuario deberá registrarse según los parámetros
establecidos por PASSEIO, el Usuario puede estar sujeto a la suscripción de otros acuerdos o instrumentos
con PASSEIO en caso de que realice una transacción a través de la plataforma digital PASSEIO o utilice los
\n\nServicios. En caso de que los Servicios proporcionados por PASSEIO, sean establecidos en un contrato
escrito y firmado de manera individual por el Usuario, dichos términos serán aplicables para uso personal y
no comercial.
            `,
    },
    {
      title: '2.1 Transacciones entre los usuarios y PASSEIO.',
      content: `
PASSEIO, S.A. DE C.V. se esfuerza por lograr que la plataforma digital PASSEIO sea útil, simple y eficiente
para hacer que las compras por internet resulten mucho más fáciles a nuestros usuarios, compradores y
viajeros. Compradores de la región podrán tener la facilidad de conectarse dentro de la plataforma
PASSEIO con familiares, amigos y otras personas que estén dispuestos a adquirir o transportar diferentes
bienes u objetos en sus viajes. De igual forma,  PASSEIO ofrece la facilidad de adquirir los objetos o
productos de interés en nombre de sus Usuarios compradores y establecer las tarifas y costos de envío
para favorecer a los Usuarios viajeros. 
\n\nLas transacciones en la plataforma PASSEIO pueden variar entre pedidos “Nacionales” o “Internacionales”
dependiendo de las necesidades de los Usuarios. Los pedidos son “Nacionales” en los casos que el origen
y destino de los objetos o bienes encargados se encuentren dentro del territorio de un mismo país. Los
pedidos son “Internacionales” en los casos que el origen o el destino de los objetos o bienes encargados se
encuentren dentro del territorio de dos países distintos.
            `,
    },
    {
      title: '2.2 Objetos Prohibidos.',
      content: `
PASSEIO recomienda a los usuarios, compradores y viajeros, que consulten a sus respectivas agencias
gubernamentales de regulación de tránsito sobre los objetos que pueden o no pueden ser enviados o
recibidos del país de origen. De igual forma, todos los viajes desde o hacia los Estados Unidos de América,
México, Canadá y demás, deben cumplir con toda la normativa aduanera, de aeropuertos y aerolíneas
aplicables.
\n\nLa siguiente lista no es taxativa, sino meramente ejemplificativa y puede estar sujeta a modificación a
discreción de PASSEIO.
\n\na)  Material peligroso definido en el Listado de Sustancias Reguladas: Sustancias Peligrosas que para
su Importación y Transporte No Requieren Elaborar Estudio de Impacto Ambiental, elaborado por el
Ministerio de Medio Ambiente y Recursos Naturales (MARN).
\n\nb)  Los artículos contenidos en la Regla X de las Reglas de Aplicación e Interpretación del Arancel
Centroamericano de Importación. (Decreto Nº 647)
\n\nc)   Material peligroso definido así por la Agencia de Protección Medioambiental de los Estados Unidos
de América (EPA); o por la Dirección de Seguridad Ocupacional y Salud de los Estados Unidos de América
(OSHA); o por el Departamento de Transportes de los Estados Unidos de América (DOT); o por la Comisión
Reguladora Nuclear de los Estados Unidos de América (NRC); o por el Departamento de Comercio de los
Estados Unidos de América (DOC); o por las Aduanas y Protección Fronteriza de los Estados Unidos de
América (CBP); o por el Departamento de Seguridad Nacional de los Estados Unidos de América (DHS); o
por cualquier otra agencia similar en los Estados Unidos de América.
\n\nd)  Otros artículos prohibidos, a título ilustrativo pero no limitativo:
\n\n·        Explosivos.
\n\n·        Artículos que se asemejen a una bomba, granada de mano u otro dispositivo
explosivo.
\n\n·        Armas de fuego, armamento y sus partes.
\n\n·        Automatizadores de armas y otros activadores para disparo rápido.
\n\n·        Armas fantasma y otras armas de fuego que no contengan un número de serie.
\n\n·        Cualquier arma de fuego o de otro tipo fabricada con una máquina de impresión
tridimensional.
\n\n·        Máquinas de impresión tridimensional diseñadas, o que funcionan exclusivamente,
para fabricar armas de fuego.
\n\n·        Productos a base de alcohol, licores, otras bebidas embriagantes y estupefacientes
en general.
\n\n·        Comestibles perecederos y alimentos o bebidas que requieran refrigeración u otro tipo
de control ambiental. Solamente se conceden excepciones por contrato entre las partes.
\n\n·        Pornografía y/o material obsceno.
\n\n·        Cadáveres o restos humanos.
\n\n·        Animales vivos.
\n\n·    Cadáveres de animales, insectos y mascotas, incluyendo trofeos de caza.
\n\n·    Plantas y material vegetal.
\n\n·    Boletos de lotería, dispositivos de juegos de azar según lo dispuesto por las leyes
vigentes.
\n\n·    Dinero,   monedas,   efectivo,   papel   moneda   e   instrumentos   negociables
equivalentes a efectivo (acciones suscritas, bonos, letras de cambio, cheques, etc.).
\n\n·    Desechos peligrosos y otros desechos médicos.
\n\n·    Artículos que puedan provocar daños o retrasos en equipos, en usuarios o terceras
personas.
\n\n·    Artículos que requieran alguna licencia o permiso especial para el transporte, la
importación o la exportación.
\n\n·    Bienes falsificados, imitaciones o pirata, que lleven una marca comercial idéntica o
sustancialmente indistinguible de una marca comercial registrada, sin previa aprobación o
supervisión del propietario del signo distintivo.
\n\n·    Tabaco y productos de tabaco.
\n\n·    Cigarrillos electrónicos y sus componentes, cualquier otro dispositivo similar que
dependa de la vaporización o aerosoles, y cualquier líquido o gel no combustible,
independientemente de la presencia de nicotina, que pueda usarse con cualquiera de
estos dispositivos.
\n\n·    Toda sustancia que no haya sido aprobada para un uso médico por la dirección o
administración   medicinal   y   de   salud   en   el   país,   como   la   Dirección   Nacional   de
Medicamentos en El Salvador, o la Administración de Alimentos y Medicamentos de los
Estados Unidos de América (FDA).
\n\n·    Drogas o medicamentos que sean ilegales según la legislación vigente.
\n\n·    Artículos cuyo valor declarado para efectos de aduana supere el límite permitido
para un destino específico.
\n\n·    Artículos que estén mojados, que goteen o despidan cualquier tipo de líquido u
olor.
\n\n·    Productos   de   la   fauna   y   la   flora   silvestres   que   requieren   autorización   de
exportación o importación.
\n\n·        En general artículos, bienes, objetos o mercancías cuyo transporte, importación o
exportación esté prohibido por alguna ley, estatuto o reglamento.
            `,
    },
    {
      title: '3. Licencia de Uso',
      content: `
PASSEIO le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, y no transferible al
Usuario sujeto al cumplimiento de estos Términos y Condiciones. La licencia consiste en: el acceso y uso
de las Aplicaciones en su dispositivo personal solo en relación con su uso de los servicios; y el acceso y uso
de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los
servicios, en cada caso solo para su uso personal, no comercial. PASSEIO y sus licenciantes se reservan
cualquier derecho que no haya sido expresamente otorgado por el presente.
            `,
    },
    {
      title: '3.1 Restricciones de la Licencia de Uso',
      content: `
El Usuario no podrá:
\n\n1.   Reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar,
revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra
forma los Servicios y/o la plataforma PASSEIO, excepto cuando sea expresamente permitido por
PASSEIO.
\n\n2.   Descompilar, realizar ingeniería inversa o desmontar servicios, excepto lo permitido por la
legislación vigente y aplicable a este tipo de relaciones contractuales.
\n\n3.   Intentar obtener un acceso no autorizado o dañar cualquier aspecto de la plataforma PASSEIO o
sus sistemas y redes relacionados. En estos casos se aplicará La Ley Especial Contra los Delitos
Informáticos y conexos, y cualquier otra legislación vigente aplicable.
\n\nTodos los derechos relativos a la plataforma PASSEIO y a los Servicios ofrecidos por la misma, son y
permanecerán de exclusiva propiedad de PASSEIO, S.A. DE C.V. “PASSEIO” o de sus licenciantes, en las
formas y tiempos en los que PASSEIO determine previamente.
\n\nNinguno de los presentes Términos y Condiciones , ni el uso de los Servicios ofrecidos por la plataforma le
transfiere u otorga ningún derecho:
\n\n1.   Sobre o en relación  con la plataforma PASSEIO o sus servicios, excepto en cuanto a la licencia
limitada otorgada anteriormente.
\n\n2.   Utilizar o mencionar en cualquier modo el nombre comercial, emblema, marca de servicio,
expresión o señal de publicidad comercial, ni cualquier otro signo distintivo de PASSEIO o de sus
licenciantes.
            `,
    },
    {
      title: '4. Uso de los Servicios',
      content: ``,
    },
    {
      title: '4.1 Registro de Usuarios',
      content: `
Con el registro de Usuario se tiene por leídos, entendidos y aceptados todos los presentes Términos y
Condiciones. En caso de no ser aceptados los Términos y Condiciones, no se habilita el registro del
Usuario para el acceso y uso de la plataforma.
\n\nTanto la plataforma, como los servicios ofrecidos por la misma, se encuentran dirigidos exclusivamente a
usuarios mayores de edad y con capacidad legal. Al aceptar los Términos y Condiciones, el Usuario declara
y afirma que cumple con los requisitos establecidos en el presente documento.

\n\nLos datos de registro requeridos por la aplicación son los siguientes:
\n\n1.   Nombre completo
\n\n2.    Número de celular
\n\n3.   Correo electrónico
\n\nEl Usuario también está obligado a proporcionar información veraz, clara y concreta de los datos
solicitados, así como cualquier otro dato relevante requerido por PASSEIO para poder brindar un
diagnóstico apropiado de los servicios aplicables en cada caso.
\n\nPASSEIO se reserva el derecho de verificar la información proporcionada por el Usuario, personal o
relacionada con los productos, enlaces, precios, y demás datos relevantes, con el fin de proporcionar
servicios que se adecuen a las posibilidades individuales de cada Usuario.
\n\nEl Usuario no podrá usar como nombre de Usuario el de otra persona; usar el documento de identidad de
otra persona; o utilizar como nombre de Usuario, un nombre ofensivo, vulgar u obsceno.
            `,
    },
    {
      title: '4.2 Acceso a la Aplicación',
      content: `
\n\nEl Usuario podrá ingresar la plataforma PASSEIO de forma libre y gratuita, y podrá acceder a la misma
mediante la aceptación de los presentes Términos y Condiciones seguido de la creación de una Cuenta de
Usuario. El Usuario será responsable de compensar a PASSEIO de cualquier costo, pérdidas, daños o
consecuencias legales que resulten de cualquier información falsa, incompleta o inexacta que sea
proporcionada por éste. El registro de Usuario es personal y no se puede transferir a terceras personas por
ningún motivo.
\n\nEl incumplimiento de alguno de los puntos señalados puede concluir en la suspensión temporal o definitiva
de la Cuenta del Usuario en cualquier momento y la eliminación de toda información relacionada con dicho
Usuario. PASSEIO se reserva expresamente de las acciones legales que estime pertinentes para los casos
de infracción de los presentes Términos y Condiciones o de la ley, así como la reserva del derecho de
poder denegar el acceso a la plataforma PASSEIO a cualquier Usuario por cualquier motivo, o cancelar
cualquier Cuenta de Usuario, oferta o “post” a su propia discreción.
            `,
    },
    {
      title: '5.0 Legislación Aplicable',
      content: `
La relación entre el Usuario y PASSEIO, según lo expuesto en los presentes Términos y Condiciones, se
regirá e interpretará de conformidad con las leyes de la República de El Salvador. Cualquier controversia o
conflicto que surja en relación con los presentes Términos y Condiciones o los Servicios, las Partes señalan
como domicilio especial la ciudad de San Salvador, departamento de San Salvador, a cuyos tribunales se
someten expresamente.
            `,
    },
  ];

  return (
    <div id="terms-conditions">
      <h1 id="title">Términos y Condiciones</h1>
      <p className="last-update">
        Última actualización: {new Date().toLocaleDateString()}
      </p>
      {sections.map((section, index) => (
        <TermSection
          key={index}
          title={section.title}
          content={section.content}
        />
      ))}
    </div>
  );
}

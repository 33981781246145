import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import { useForm } from 'react-hook-form';
import logo from '../../../assets/img/logos/col.png';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useAuth } from '../../../hooks/useAuth';
import { auth } from '../../../util/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

export function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const { profile, localUser, user } = useAuth();
  const { setValue, handleSubmit } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile || localUser || user) navigate('/', { replace: true });
  }, [profile, navigate]);

  const onSubmit = async (data) => {
    if (!data.email) {
      CustomToast('error', 'Asegurate de llenar todos los campos');
      return;
    }
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, data.email).then(() => {
        CustomToast('success', 'Correo enviado exitosamente');
        navigate('/login', { replace: true });
      });
    } catch {
      CustomToast(
        'error',
        'No se ha podido enviar el correo, por favor intentalo mas tarde',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="loginPage">
      <Link to="/" className="back-arrow">
        <IoIosArrowRoundBack size={50} color="#fecf41" />
      </Link>
      <div className="loginContainer">
        <img
          alt="logo"
          src={logo}
          style={{
            width: 200,
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className="authLabel">Correo electrónico</label>
            <Input
              type="email"
              className="mainInput"
              placeholder="johndoe@example.com"
              onChange={(e) => setValue('email', e.target.value)}
            />
          </div>
          <Button type="submit" className="primaryBtn" disabled={loading}>
            Restablecer
          </Button>
          <p>
            ¿Ya tienes cuenta?{' '}
            <Link className="authLink" to="/login">
              Inicia sesion
            </Link>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export function getAsinFromUrl(url) {
  const match = url.match(/\b[A-Z0-9]{10}\b/i);
  return match ? match[0] : null;
}

export const calculateWeight = (infoWeigthAmazon) => {
  const infoValue =
    infoWeigthAmazon && typeof infoWeigthAmazon === 'object'
      ? infoWeigthAmazon?.value
      : infoWeigthAmazon;
  const cleanString = (infoValue || '')
    ?.toString()
    ?.replace(/[^\x20-\x7E]/g, '');
  const [weightStr, type] = cleanString?.split(' ') || [];
  let weight = Number(weightStr) || 0;
  const lowerType = type?.toLowerCase() || '';
  switch (true) {
    case lowerType.includes('ounces'):
      weight /= 16;
      break;
    case lowerType.includes('kilograms'):
      weight *= 2.20462;
      break;
    case lowerType.includes('grams'):
      weight /= 453.592;
      break;
    default:
      break;
  }
  return Number(weight.toFixed(2));
};

export const getPriceFromProductDetails = (product) => {
  if (!product) return null;
  return (
    product?.price?.value ||
    product?.buybox_winner?.join_prime_price?.value ||
    product?.buybox_winner?.price?.value ||
    null
  );
};

export const getAsinFromProductDetails = (product) => {
  if (!product) return null;
  return (
    product.asin ||
    product.specifications?.find((spec) => spec.name === 'ASIN')?.value ||
    null
  );
};

export const getWeightFromProductDetails = (product) => {
  if (!product) return null;
  return (
    product.weight ||
    product.specifications?.find((spec) => spec.name === 'Item Weight')
      ?.value ||
    product.specifications?.find((spec) => spec.name === 'Package Weight')
      ?.value ||
    null
  );
};

export const getWeigthFromProductDimensions = (product) => {
  if (!product) return null;
  return (
    product.specifications?.find((value) => value.name === 'Package Dimensions')
      ?.value ||
    product?.specifications?.find(
      (value) => value.name === 'Product Dimensions',
    )?.value ||
    null
  );
};

export function getWeigthItemDimesion(product) {
  if (!product) return null;
  const regex = /(\d+(\.\d+)?)\s*(ounces?|grams?|pounds?|kilograms?)/i;
  const match = product.toLowerCase().match(regex);
  if (match) return match[0];
  return null;
}

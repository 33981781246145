import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Button, Input } from 'reactstrap';

const PasswordInput = ({ value, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Input
        type={showPassword ? 'text' : 'password'}
        className="mainInput"
        placeholder="**********"
        onChange={onChange}
        value={value}
      />
      <Button
        type="button"
        style={{
          marginLeft: '-40px',
          marginTop: '5px',
          width: 'fit-content',
          background: 'transparent',
          padding: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={() => setShowPassword(!showPassword)}
        aria-label="Toggle password visibility"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </Button>
    </>
  );
};

export default PasswordInput;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { Input, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import {
  calculateWeight,
  getPriceFromProductDetails,
} from '../../../util/amazon';
import AmazonService from '../../../services/amazon.service';
import FirebaseService from '../../../services/firebase.service';

export function AdminCreateProductPage() {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { setValue, getValues, watch, handleSubmit } = useForm({
    defaultValues: {
      nameProduct: '',
      urlPhoto: '',
      weigthEstimate: 0,
      currentWeigth: 0,
      price: null,
      asin: '',
      urlProduct: '',
      category: '',
      comment: '',
      status: true,
    },
  });
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const asin = watch('asin');
    if (fetching) {
      if (isValidASIN(asin)) {
        fetchProductData(asin);
      } else {
        CustomToast('error', 'ASIN inválido');
        setFetching(false);
      }
    }
  }, [fetching]);

  const isValidASIN = (asin) => {
    const asinRegex = /^[A-Z0-9]{10}$/;
    return asinRegex.test(asin);
  };

  const fetchProductData = async (asin) => {
    try {
      setLoading(true);
      await amazonService.detail(asin).then((response) => {
        const price = getPriceFromProductDetails(response?.product);
        setValue('nameProduct', response?.product?.title);
        setValue('urlPhoto', response?.product?.main_image?.link);
        setValue('urlProduct', response.product?.link);
        setValue('category', response?.product?.categories[0]?.name);
        setValue('weigthEstimate', calculateWeight(response?.product?.weight));
        setValue('currentWeigth', calculateWeight(response?.product?.weight));
        setValue('price', price || 0);
      });
    } catch {
      CustomToast('error', 'Ocurrió un error al buscar el producto');
    } finally {
      setLoading(false);
      setFetching(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await validateId(data.asin);
      await firebaseService.createHistoryProduct(data);
      navigate(-1);
    } catch {
      CustomToast('error', 'Ocurrió un error al crear el producto');
    } finally {
      setLoading(false);
    }
  };

  const validateId = async (id) => {
    const response = await firebaseService.validateHistoryProduct(id);
    if (!response) throw new Error('El ASIN ya existe');
  };

  if (loading) return <PageLoader />;

  return (
    <div className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Crear Producto
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">ASIN</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <Input
            required
            type="text"
            className="mainInput"
            placeholder="B092QGSJ36"
            defaultValue={getValues('asin')}
            onChange={(e) => setValue('asin', e.target.value)}
          />
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          >
            <Button
              onClick={() => setFetching(true)}
              className="primaryBtn"
              style={{ width: 'min-content' }}
            >
              Buscar
            </Button>
          </div>
        </div>
        <p className="label">Nombre</p>
        <Input
          required
          type="text"
          className="mainInput"
          defaultValue={getValues('nameProduct')}
          onChange={(e) => setValue('nameProduct', e.target.value)}
          placeholder="Computer"
        />
        <p className="label">Peso Estimado</p>
        <Input
          required
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('weigthEstimate')}
          onChange={(e) => setValue('weigthEstimate', e.target.value)}
          placeholder="0"
        />
        <p className="label">Peso Real</p>
        <Input
          required
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('currentWeigth')}
          onChange={(e) => setValue('currentWeigth', e.target.value)}
          placeholder="0"
        />
        <p className="label">Precio</p>
        <Input
          type="number"
          min={0}
          step={0.01}
          className="mainInput"
          defaultValue={getValues('price')}
          onChange={(e) => setValue('price', e.target.value)}
          placeholder="0"
        />
        <p className="label">Comentarios</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('comment')}
          onChange={(e) => setValue('comment', e.target.value)}
          placeholder="Good product!"
        />
        <p className="label">Link</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('urlProduct')}
          onChange={(e) => setValue('urlProduct', e.target.value)}
          placeholder="https://amazon.com"
        />
        <p className="label">Categoria</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('category')}
          onChange={(e) => setValue('category', e.target.value)}
          placeholder="Electronics"
        />

        {getValues('urlPhoto') && (
          <>
            <p className="label">Imagen</p>
            <img
              src={getValues('urlPhoto')}
              alt="product"
              width={250}
              height={200}
              style={{ objectFit: 'cover', marginTop: '10px' }}
            />
          </>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '0.75rem',
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input text-white"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked={getValues('status')}
              onChange={(e) => setValue('status', e.target.checked)}
            />
            <label
              className="form-check-label text-white"
              htmlFor="flexCheckChecked"
            >
              Producto activo
            </label>
          </div>
        </div>

        <Button
          type="submit"
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
          disabled={loading}
        >
          Guardar
        </Button>
      </form>
    </div>
  );
}
